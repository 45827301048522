import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const PrivateRoute = ({ children }) => {
    const navigate = useNavigate();
    const { currentUser } = useContext(AuthContext);

    const handleClick = (e) => {
        if (!currentUser) {
            e.preventDefault();
            navigate("/entrar");
        }
    };

    return (
        <span onClick={handleClick}>
            {children}
        </span>
    );
};

export default PrivateRoute