import styled from "styled-components";
import theme from "../../theme";

const WrapperPayment = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 25%;
    margin-top: 100px;
    margin-bottom: 170px;

    .payment-header {
        width: 35%;
        display: flex;
        flex-direction: column;

        .title {
            font-size: 2.3rem;
            font-family: ${theme.fontFamily.bold};
        }

        .info {
            font-size: 1.1rem;
        }

        .body {
            margin-top: 20px;
            padding: 10px;
            border-radius: 12px;
            border: 1px solid #9e9e9e;

            .radio-group{
                display: flex;
                flex-direction: column;
                gap: 10px;

                label {
                    display: flex;
                    align-items: center;
                    background-color: #e6e6e6;
                    border-radius: 5px;
                    padding: 7px 0px;
                    padding-left: 20px;

                    .name {
                        display: flex;
                        align-items: center;
                        gap: 7px;

                        svg {
                            font-size: 25px;
                        }
                    }
                }
            }
        }
    }

    .custom-radio input[type="radio"] {
        display: none;
    }

    .custom-radio {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .custom-radio-circle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid ${theme.fontColor.green};
        margin-right: 10px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .custom-radio input[type="radio"]:checked + .custom-radio-circle::after {
        content: '✓';
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        color: white;
        width: 20px;
        height: 20px;
        background-color: ${theme.fontColor.green};
        border-radius: 50%;
        position: absolute;
    }

    .custom-radio span {
        font-size: 16px;
        color: #333;
    }

    .input-area {
        width: 65%;
    }

    .actions {
        width: 100%;
        display: flex;
        justify-content: center;

        .next {
            margin: 0 auto;
            margin-top: 80px;
            padding: 5px 35px;
            background-color: ${theme.fontColor.green};
            color: white;
            text-align: center;
            border-radius: 100px;
            font-family: ${theme.fontFamily.medium};
            font-size: 1.2rem;
            cursor: pointer;
        }
    }

    .button.pay{
        margin: 0 auto;
        margin-top: 80px;
        padding: 7px 0px;
        width: 20%;
        background-color: ${theme.fontColor.green};
        color: white;
        text-align: center;
        border-radius: 100px;
        font-family: ${theme.fontFamily.medium};
        font-size: 1.2rem;
        cursor: pointer;
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        margin: 60px 10%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .payment-header {
            width: 100%;

            .title {
                font-size: 1.7rem;
            }

            .info {
                font-size: .9rem;
            }
        }

        .input-area{
            width: 100%;

            ul {
                width: 100%;

                li {
                    word-break: break-all;
                }
            }
        }

        .button.pay{
            margin-top: 30px;
            font-size: 1.3rem;
            padding: 10px 0px;
            width: 30%;
        }  
    }
    
    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        margin: 0 15%;
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .payment-header {
            width: 100%;

            .title {
                font-size: 1.7rem;
            }

            .info {
                font-size: .9rem;
            }
        }

        .input-area{
            width: 100%;

            ul {
                width: 100%;
                li {
                    width: 100%;
                }
            }
        }

        .button.pay{
            margin-top: 30px;
            font-size: 1rem;
        }   

    }
    
    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        margin: 0 15%;
        margin-top: 60px;

        .payment-header {

            .title {
                font-size: 1.7rem;
            }

            .info {
                font-size: .9rem;
            }
        }

        .button.pay{
            margin-top: 30px;
            font-size: 1rem;
        }
    }
    
    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        margin: 0 20%;
        margin-top: 60px;

        .payment-header {
            
            .title {
                font-size: 2rem;
            }

            .info {
                font-size: 1rem;
            }
        }
    }
    
    @media (max-width: ${theme.breackpoint.bigScreens}) and (min-width: ${theme.breackpoint.largeScreens}) {
        margin: 0 20%;
        margin-top: 60px;
    }
    
`

const WrapperPix = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title-session{
        font-size: 1.5rem;
        font-family: ${theme.fontFamily.medium};
        margin-bottom: 10px;
    }

    img {
        width: 30%;
    }

    .pix-code {
        outline: none;
        border: none;
        padding: 6px 10px;
        margin-top: 30px;
        border-radius: 10px;
        font-size: 1rem;
        width: calc(85% - 20px);
        border: 1px solid #9e9e9e;
        background-color: #f4f4f4;
        margin-bottom: 30px;
    }

    .button {
        background-color: ${theme.fontColor.green};
        font-family: ${theme.fontFamily.regular};
        border-radius: 7px;
        color: white;
        padding: 7px 0px;
        width: 40%;
        text-align: center;
        margin-bottom: 10px;
        cursor: pointer;
    }

    ul{
        margin-top: 30px;
        width: calc(90% - 20px);

        li{
            margin-bottom: 10px;
            white-space: nowrap;
        }
    }

    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        
    }

    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        
        .title {
            font-size: 1.3rem;
        }

        .pix-code{
            font-size: 1.1rem;
        }

        ul {
            margin-bottom: 10px;

            li {
                font-size: .8rem;
            }
        }
    }

    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        .title-session{
            font-size: 1.3rem;
        }

        .pix-code{
            font-size: 1.2rem;
        }
    }

`

const WrapperBoleto = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .title-session{
        font-size: 1.2rem;
        font-family: ${theme.fontFamily.medium};
        width: 50%;
        text-align: center;
    }

    .adrress {
        display: flex;
        flex-wrap: wrap;
        width: 80%;
        gap: 5px;

        .title {
            width: 100%;
            font-size: 1.4rem;
            margin-left: 10px;
            margin-bottom: 2px;
            font-family: ${theme.fontFamily.medium};
        }

        input {
            width: calc(49% - 24px);
            background-color: #f4f4f4;
            font-size: 1.1rem;
            font-family: ${theme.fontFamily.regular};
            padding: 10px;
            outline: none;
            border: none;
            border: 2px solid #d7d7d7;
            border-radius: 10px;
            margin-bottom: 5px;
        }

        .button-container{
            width: 100%;
            display: flex;
            margin-top: 15px;

            .button {
                padding: 3px;
                width: calc(48%);
                text-align: center;
                background-color: ${theme.fontColor.green};
                color: white;
                border: none;
                user-select: none;


                &.deactive{
                    background-color: #9e9e9e;
                    cursor: initial;
                }
            }
        }

        .line {
            display: flex;
            justify-content: space-between;

            input:first-child {
                width: 70%;
            }

            input:last-child {
                width: 20%;
            }
        }
    }

    .barcode {
        width: 50%;
        margin-top: 10px;
        margin-bottom: 10px;
        height: 70px;

        svg {
            width: 100%;
            margin-top: -40px;

            rect{
                
            }
        }
    }

    .subtitle-session {
        font-size: 1.1rem;
        margin-bottom: 10px;
    }

    .button {
        text-decoration: none;
        color: black;
        padding: 8px 0px;
        width: 50%;
        font-family: ${theme.fontFamily.medium};
        font-size: 1.2rem;
        text-align: center;
        border: 1px solid #9e9e9e;
        margin-bottom: 10px;
        border-radius: 10px;
        cursor: pointer;
    }

    .title{
        font-family: ${theme.fontFamily.medium};
        align-self: flex-start;
        margin-left: 65px;
        margin-top: 20px;
    }

    ul {
        width: 80%;

        li {
            margin-bottom: 10px;
        }
    }

    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        .title-session{
            font-size: 1.15rem;
        }
        
        .subtitle-session {
            font-size: 1rem;
        }
        
        .button {
            font-size: 1.1rem;
        }
    }
` 

const WrapperCartaoCredito = styled.div`
    width: 90%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;

    .card {
        display: flex;
        flex-direction: column;

        .title {
            font-size: 1.2rem;
            margin-left: 10px;
            margin-bottom: 2px;
            font-family: ${theme.fontFamily.medium};
        }

        input {
            width: calc(100% - 24px);
            background-color: #f4f4f4;
            font-size: 1rem;
            font-family: ${theme.fontFamily.regular};
            padding: 10px;
            outline: none;
            border: none;
            border: 2px solid #d7d7d7;
            border-radius: 10px;
            margin-bottom: 5px;
        }

        .line {
            display: flex;
            justify-content: space-between;

            input:first-child {
                width: 70%;
            }

            input:last-child {
                width: 20%;
            }
        }
    }

    .button {
        text-decoration: none;
        color: black;
        padding: 4px 13px;
        align-self: flex-start;
        font-family: ${theme.fontFamily.medium};
        background-color: ${theme.fontColor.green};
        color: white;
        font-size: 1rem;
        text-align: center;
        margin-bottom: 10px;
        border-radius: 10px;
        margin-top: 10px;
        cursor: pointer;

        &.deactived {
            background-color: #9e9e9e;
            color: white;
        }
    }

    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        .card {
            .title {
                font-size: 1.2rem;
            }

            input {
                padding: 7px 10px;
                font-size: 1rem;
            }
        }
    }
`

export {
    WrapperPayment,
    WrapperPix,
    WrapperBoleto,
    WrapperCartaoCredito
}