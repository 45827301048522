import NavBar from "../../components/nav-bar/NavBar";
import WrapperMyData from "../my-data/MyData.styles";
import WrapperTermsOfUse from "./TermsOfUse.styles";

const TermsOfUse = ({data}) => {

    return <WrapperMyData>
        <NavBar data={data} />
        <div className="container-f">
            <span className="title">Termos de Uso</span>
            <span className="body">
            Escrito por Compre Imóveis
            <br /><br />
            Esta seção estabelece os termos e condições (doravante, as “Condições Gerais”) para o uso
            dos conteúdos e serviços (doravante, os “Serviços”) do site especificado na cláusula 12
            (doravante, o “Site”). O Site e os Serviços são prestados pela empresa detalhada na cláusula 12
            (doravante, “Compre Imóveis”).
            <br /><br />
            Caso não esteja de acordo com as Condições Gerais, por favor, abandone o Site e abstenha-se
            de utilizar os Serviços que são oferecidos. A utilização, pelo usuário, do Site será entendida
            como aceitação plena e sem reservas das Condições Gerais aqui estabelecidas.
            <br /><br />
            Esta seção estabelece os termos e condições (doravante, as “Condições Gerais”) para o uso
            dos conteúdos e serviços (doravante, os “Serviços”) do site especificado na cláusula 12
            (doravante, o “Site”). O Site e os Serviços são prestados pela empresa detalhada na cláusula 12
            (doravante, “Compre Imóveis”).
            <br /><br />
            Caso não esteja de acordo com as Condições Gerais, por favor, abandone o Site e abstenha-se
            de utilizar os Serviços que são oferecidos. A utilização, pelo usuário, do Site será entendida
            como aceitação plena e sem reservas das Condições Gerais aqui estabelecidas.
            <br /><br />
            Doravante, os termos “Você” e “Usuário” serão utilizados para fazer referência a todas as
            pessoas físicas e/ou jurídicas que, por qualquer razão, acessem o Site.
            <br /><br />
            <br /><br />
            <strong>ACESSO AO SITE.</strong>
            <br /><br />
            <strong>1.1. Acesso ao Site.</strong>
            <br /><br />
            O acesso e a utilização do Site não exigem a prévia assinatura ou registro do Usuário.
            Entretanto, é possível que a utilização de alguns dos Serviços oferecidos por meio do
            Site requeira a assinatura ou registro do Usuário e/ou o pagamento de um preço.
            <br /><br />
            1.2. <strong>Utilização do Site.</strong>
            <br /><br />
            1.2.1. O Usuário compromete-se a utilizar o Site em conformidade com estas
            Condições Gerais, as leis aplicáveis conforme a cláusula 12 e com a moral e os bons
            costumes.
            <br /><br />
            1.2.2. O Usuário obriga-se a abster-se de utilizar o Site com fins ou efeitos ilícitos,
            contrários ao estabelecido nas Condições Gerais, lesivos aos direitos e interesses de
            terceiros, ou que de qualquer forma possam danificar, inutilizar, sobrecarregar ou
            deteriorar o Site ou impedir a normal utilização do Site por parte dos Usuários.
            <br /><br />
            1.3. <strong>Conteúdo do Site.</strong>
            <br /><br />
            1.3.1. Os conteúdos deste Site, tais como texto, informação, gráficos, imagens, logos,
            marcas, programas de computação, bancos de dados, desenhos, arquitetura funcional
            e qualquer outro material (doravante, o “Conteúdo”) estão protegidos pelas leis
            aplicáveis em cada jurisdição conforme a cláusula 12 incluindo, mas sem limitação, as
            leis sobre direitos autorais, patentes, marcas, modelos de utilidade, desenhos
            industriais e nomes de domínio.
            <br /><br />
            1.3.2. Todo o Conteúdo é propriedade da Compre Imóveis e/ou de qualquer outra
            sociedade vinculada e/ou de seus provedores de conteúdo. A compilação,
            interconexão, operacionalidade e disposição dos conteúdos do Site é de propriedade
            exclusiva da Compre Imóveis e/ou de suas empresas vinculadas. O uso, adaptação,
            reprodução e/ou comercialização não autorizada do Conteúdo pode ser penalizado
            penado pela legislação vigente em cada jurisdição.
            <br /><br />
            1.3.3. Você não copiará nem adaptará o código de programação desenvolvido pela
            Compre Imóveis, ou por conta da Compre Imóveis para gerar e operar suas páginas,
            código esse que está protegido pela legislação aplicável e vigente em cada jurisdição.
            <br /><br />
            1.4. <strong>Usos proibidos</strong>
            <br /><br />
            1.4.1. <strong>Regras gerais</strong>: Os Usuários estão proibidos de utilizar o Site para transmitir, distribuir,
            armazenar ou destruir material (i) violando a normativa vigente, (ii) de forma que sejam
            infringidos direitos de terceiros ou seja violada a confidencialidade, a honra, a privacidade, a
            imagem ou outros direitos pessoais de outras pessoas.
            <br /><br />
            1.4.2. O Site somente poderá ser utilizado com fins lícitos, para acessar a informação referida
            aos Serviços disponíveis por meio do mesmo. A Compre Imóveis proíbe especificamente
            qualquer utilização do Site para:
            <br /><br />
            • Anunciar dados biográficos incompletos, falsos ou inexatos.
            <br /><br />
            • Registrar mais de uma conta correspondente a um mesmo usuário.
            <br /><br />
            • Usar qualquer mecanismo para impedir ou tentar impedir o funcionamento adequado
            deste Site ou qualquer atividade que esteja sendo realizada neste Site.
            <br /><br />
            • Revelar ou compartilhar a sua senha com terceiros, ou usar sua senha para propósitos
            não autorizados.
            <br /><br />
            • O uso ou tentativa de uso de qualquer máquina, software, ferramenta, agente ou
            outro mecanismo para navegar ou buscar neste Site que sejam diferentes das
            ferramentas de busca fornecidas pela Compre Imóveis neste Site.
            <br /><br />
            • Tentar decifrar, decompilar ou obter o código fonte de qualquer programa de software
            deste Site.
            <br /><br />
            1.4.3. A Compre Imóveis reserva-se o direito de suspender ou dar baixa a qualquer Usuário
            que, a exclusivo critério da Compre Imóveis, não cumprir os padrões definidos nestas
            <br /><br />
            Condições Gerais ou as políticas vigentes da Compre Imóveis, sem que isto gere direito a
            ressarcimento algum.
            </span>
        </div> 
    </WrapperMyData>
}

export default TermsOfUse