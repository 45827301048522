import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';

const fetchUserData = (uid) => {
    const userDocRef = doc(db, 'users', uid);

    return getDoc(userDocRef)
        .then((userDoc) => {
            if (userDoc.exists()) {
                const userData = userDoc.data();
                return ({ uid, ...userData });
            } else {
                console.error('Nenhum documento encontrado!');
                return null;
            }
        })
        .catch((error) => {
            console.error("Erro ao buscar dados do usuário:", error);
            throw error;
        });
}

export default fetchUserData;