import { Link, useNavigate } from "react-router-dom"
import SBlogHome from "./styles"
import BlogMain from "../../imgs/blog/BLOG BLOG QUADRADO 02.png"
import Blog1 from "../../imgs/blog/BLOG QUADRADO PEQUENO 01.png"
import Blog2 from "../../imgs/blog/BLOG QUADRADO PEQUENO 03.png"
import Blog3 from "../../imgs/blog/BLOG QUADRADO PEQUENO 04.png"
import { useEffect, useState } from "react"
import { getAllBlogPosts } from "../../hooks/firebase"


export default function BlogHome(props){
    const [main, setMain] = useState({})
    const [another, setAnother] = useState([])
    const navigate = useNavigate()




    const truncateText = (text, maxLength = 60) => {
        if (text.length > maxLength) {
          return text.slice(0, maxLength) + "...";
        }
        return text;
        };

         

    useEffect(()=>{
        getAllBlogPosts().then(result => {
            let anothers = []
            result.forEach(blog => {
                if (blog.isMain) {
                    setMain({
                        ...blog,
                        body: truncateText(blog.body)
                    });
                } else {
                    anothers.push({
                        ...blog,
                        body: truncateText(blog.body)
                    })
                }
                setAnother(anothers)
            })
        })
        // const interval = setInterval(() => {
        //     let banners = document.querySelectorAll(".banner .bannerBlog")
        //     let len = banners.length
  
        //     for(var i = 0; i < len; i++){
        //         if (banners[i].classList.contains("active")){
        //             if (len-1 == i){
        //                 //ultimo
        //                 banners[i].classList.remove("active")
        //                 banners[0].classList.add("active")
        //             } else {
        //                 banners[i].classList.remove("active")
        //                 banners[i+1].classList.add("active")
        //             }

        //             break
        //         }
        //     }
        //   }, 5000);
        
        // return () => clearInterval(interval);
    },[])

    const blogAPIMock = [
        {img: Blog1, title: "O que é Terapia e sua importância", snippet: "Você já se perguntou, ou até mesmo foi uma daquelas pessoas que tem “medo” d", link: "o-que-e-terapia-e-sua-importancia"},
        {img: Blog2, title: "TDAH - O que é, causas e tratamentos", snippet: "O Transtorno do Déficit de Atenção e Hiperatividade (TDAH) é", link: "tdah-o-que-e-causas-e-tratamentos"},
        {img: Blog3, title: "O que é Burnout e seus sintomas?", snippet: "O Burnout é um distúrbio psicológico relacionado ao trabalho", link: "o-que-e-burnout-e-seus-sintomas"},
    ]

    const BlogComponent = props => {
        return (
            <div className="blog" data-sa={props.sa}>
                <Link className="img" to={props.link}>
                    <img src={props.img} />
                </Link>
                <div className="infos">
                    <span className="title">{props.title}</span>
                    <span className="snippet">{props.snippet}</span>
                    <Link className="readMore" to={props.link}>
                        Leia Mais
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </Link>
                </div>
            </div>
        ) 
    }

    

    return (
        <SBlogHome>
            <div className="blogs">
                <span className="title" data-sa="left">
                    Tendências
                </span>
                <div className="primary" data-sa="left">
                    <Link to={main.slug}> 
                        <img src={main.bannerDesktop} />
                    </Link>
                    {/* <span className="date">15 de fevereiro de 2023</span> */}
                    <span className="title">{main.title}</span>
                    <span className="snippet">{main.body}</span>
                    <Link className="readMore">
                        Leia Mais 
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </Link>
                </div>
                <div className="secondary">
                    {another.map((blog) => {
                        return <BlogComponent
                            sa="right"
                            img={blog.bannerMobile}
                            title={blog.title}
                            snippet={blog.body}
                            link={blog.slug}
                        />
                    })}
                </div>
            </div>
        </SBlogHome>
    )
}