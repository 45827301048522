import styled from "styled-components";
import theme from "../../theme"

const WrapperPlan = styled.div`
    width: 20%;
    padding: 1.5%;
    border: 2px solid #151515;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    order: ${(props) => props.order};;

    .best-plan {
        width: 130px;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-60px) translateX(30px);
    }

    .title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: ${theme.fontFamily.extraBold};
        font-size: 3rem;
        border-bottom: 2px solid ${theme.fontColor.green};
        padding-bottom: 5px;
        color: ${theme.fontColor.green};
        height: 80px;

        img {
            width: 70px;
            margin-right: 20px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        width: 95%;
        margin: 0 auto;
        margin-bottom: 50px;
        gap: 25px;
        align-self: flex-start;


        .item {
            display: flex;
            align-items: center;
            gap: 15px;

            img {
                width: 1.5rem ;
            }

            svg {
                font-size: 24px;
            }

            span {
                font-size: 1rem;
                font-family: ${theme.fontFamily.regular};
            }

        }
    }

    .button {
        cursor: pointer;
        align-self: flex-end;
        bottom: 0;
        width: 100%;
        padding: 5px 0px;
        margin-top: 40px;
        text-align: center;
        background-color: ${theme.fontColor.green};
        color: white;
        font-family: ${theme.fontFamily.extraBold};
        font-size: 1.2rem;
        border-radius: 15px;
        margin-bottom: 10px;
        bottom: 0;
        margin-top: auto;
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        width: 90%;
        padding: 5%;
    }
    
    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        width: calc(44% - 20px);
        padding: 2%;

        .best-plan{
            width: 100px;
            transform: translateY(-30px) translateX(40px);
        }
    }
    
    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        .title {
            font-size: 2.5rem;
        }
        .best-plan{
            width: 100px;
            transform: translateY(-50px) translateX(40px);
        }
        .content {
            .item {
                img {
                    width: 1.3rem;
                }
                span {
                    font-size: .9rem;
                }
            }
            .item.price{
                font-size: 2rem;
                margin-bottom: 0px;
            }
        }
    }
    
    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        .title {
            font-size: 2.5rem;
        }
        .best-plan{
            width: 100px;
            transform: translateY(-50px) translateX(40px);
        }
        .content {
            .item {
                img {
                    width: 1.3rem;
                }
                span {
                    font-size: .9rem;
                }
            }
            .item.price{
                font-size: 2rem;
                margin-bottom: 0px;
            }
        }
    }
    
    @media (max-width: ${theme.breackpoint.bigScreens}) and (min-width: ${theme.breackpoint.largeScreens}) {
        
    }
    
`

export default WrapperPlan