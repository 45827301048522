import styled from "styled-components";
import theme from "../../theme";

const WrapperMostSearched = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 18%;
    margin-top: 150px;

    .title{
        font-size: 1.5rem !important;
        font-family: ${theme.fontFamily.regular};
        letter-spacing: 1px; 
        text-align: center;  
        margin-bottom: 45px;
    }

    .container {
        display: flex;
        justify-content: space-between;
    }
 
    @media (max-width: ${theme.breackpoint.mobile_2}) {
        margin: 0 3%;
        margin-top: 80px;

        .title {
            font-size: 30px;
            align-self: center;
            margin-bottom: 30px;
        }

        .container {
            flex-wrap: wrap;
            gap: 20px;
        }
    }

    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        margin-top: 65px;
        align-items: center;

        .title {
            font-size: 40px;
            align-self: center;
            margin-bottom: 30px;
        }

        .container {
            width: 80%;
            flex-wrap: wrap;
            gap: 20px;
        }
    }

    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        margin-top: 65px;
        align-items: center;

        .title {
            font-size: 40px;
            align-self: center;
            margin-bottom: 30px;
        }

        .container {
            gap: 20px;
        }
    }

    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        margin-top: 65px;
        align-items: center;

        .title {
            font-size: 40px;
            align-self: center;
            margin-bottom: 30px;
        }

        .container {
            gap: 20px;
        }
    }

    @media (max-width: ${theme.breackpoint.bigScreens}) and (min-width: ${theme.breackpoint.largeScreens}) {
        margin-top: 65px;
        align-items: center;

        .title {
            font-size: 40px;
            align-self: center;
            margin-bottom: 30px;
        }

        .container {
            gap: 20px;
        }
    }
`

export default WrapperMostSearched