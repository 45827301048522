import styled from "styled-components";
import theme from "../../theme";

const WrapperFooter = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 100px;

    .top {
        background-color: ${theme.backgroundColor.green};
        padding: 0 18%;
        padding-top: 50px;
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;

        .column{
            width: 23%;
            display: flex;
            flex-direction: column;


            .title {
                color: white;
                font-size: 1.3rem;
                font-family: ${theme.fontFamily.medium};
            }
            
            a {
                color: white;
                text-decoration: none;
                font-size: 1.1rem;
            }

            .button {
                padding: 7px 25px;
                border-radius: 5px;
                background-color: white;
                color: ${theme.fontColor.green};
                align-self: flex-start;
                font-size: 1.3rem;
                font-family: ${theme.fontFamily.medium};
                font-weight: 600;
                cursor: pointer;
            }

            .description {
                font-size: 1rem;
                color: white;
            }

            .newsletter-input {
                margin: 14px 0px;
                font-size: 1.1rem;
                outline: none;
                border: none;
                padding: 10px 10px;
                width: calc(100% - 25px0px);
                background-color: white;
                border-radius: 5px;

                &::placeholder{
                    color: #dedede;
                }
            }

            .social {
                font-size: 1.5rem;
                color: white;

              
            }

            .social-midias{
                font-size: 1.7rem;
                color: white;
                display: flex;
                gap: 5px;
                margin-top: 10px;

                svg {
                    cursor: pointer;
                }

                
                svg:first-child{
                    font-size: 1.6rem;
                }

                svg:last-child{
                    margin-left: 4px;
                }
            }
        }
    }

    .bottom {
        background-color: ${theme.backgroundColor.green3};
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 20px 0;
        font-size: .895rem;
        color: white;
        text-align: center;
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {

        .top {
            flex-wrap: wrap;
            gap: 30px;
            padding: 0 10%;
            padding-top: 50px;
            padding-bottom: 20px;

            .column {
                width: 100%;
                align-items: center;

                a {
                    font-size: 1rem;
                }

                

                .button {
                    margin-top: 5px;
                    align-self: center;
                }

                .description {
                    text-align: center;
                    font-size: 1rem;
                }

                &.social{
                    flex-direction: row !important;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .title {
                        width: 100%;
                        text-align: center;
                    }

                }

                &.links{
                    flex-direction: row !important;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 80%;
                    margin: 0 auto;

                    .title {
                        width: 100%;
                        text-align: center;
                    }

                }
            }
        }
    }
    
    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        .top {
            padding: 0 8%;
            padding-top: 50px;
            padding-bottom: 20px;
            flex-wrap: wrap;
            gap: 10px; 

            .column {
                width: 49%;

                .title {
                    font-size: 1.1rem;
                }
                a {
                    font-size: 1rem;
                }
                .description {
                    font-size: 1rem;
                }
            }
        }
    }
    
    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        .top {
            padding: 0 8%;
            padding-top: 50px;
            padding-bottom: 20px;

            .column {
                .title {
                    font-size: 1.1rem;
                }

                a {
                    font-size: 1rem;
                }

                .description {
                    font-size: 1rem;
                }
            }
        }
    }
    
    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        .top {
            padding: 0 10%;
            padding-top: 50px;
            padding-bottom: 20px;
            
            .column {
                .title {
                    font-size: 1.1rem;
                }
                .description {
                    font-size: 1rem;
                }
            }
        }
    }
    
    @media (max-width: ${theme.breackpoint.bigScreens}) and (min-width: ${theme.breackpoint.largeScreens}) {
    
    }
    
`

export default WrapperFooter