import styled from "styled-components";
import theme from "../../theme";


const WrapperInfos = styled.div`
    margin: 170px 22%;
    display: flex;
    justify-content: space-between;

 
    .card {
        width: 24.36%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        img {
            width: 80px;
            margin-bottom: 60px;
        }

        &:nth-child(1) {
            img {
                width: 65px;
                margin-bottom: 40px;
            }
        }

        &:nth-child(2) {
            img {
                width: 100px;
                margin-bottom: 40px;
            }
        }

        &:nth-child(3) {
            width: 25.36%;

            img {
                margin-bottom: 40px;
            }

            .title {
                width: 80%;
            }
        }

        .title {
            font-size: 1.4rem !important;
            margin-bottom: 5px;
            font-family: ${theme.fontFamily.bold};
        }

        .description {
            font-size: 1.2rem !important;
            font-family: ${theme.fontFamily.regular};
            margin-top: -10px;
        }
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        flex-direction: column;
        align-items: center;
        gap: 80px;

        .card {
            width: 80% !important;

            img {
                margin-bottom: 30px !important;
            }

            .title {
                font-size: 32px;
                width: 100% !important;
            }

            .description {
                font-size: 23px;
            }
        }
    }

    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        margin: 130px 6.375%;

        flex-direction: column;
        align-items: center;
        gap: 80px;

        .card {
            width: 70% !important;

            img {
                margin-bottom: 30px !important;
            }

            .title {
                font-size: 32px;
            }

            .description {
                font-size: 23px;
            }
        }
    }

    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        margin: 150px 20%;

        .card {

            img {
                width: 60px !important;
            }

            .title {
                font-size: 1.1rem !important;
                width: 100% !important;
            }

            .description {
                font-size: .9rem !important;
            }
        }
    }

    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        margin: 150px 20%;
        .card {
            .title {
                font-size: 1.3rem !important;
                width: 100% !important;
            }

            .description {
                font-size: 1.1rem !important;
            }
        }
    }
` 

export default WrapperInfos