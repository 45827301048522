import { useState, useEffect, useContext } from "react";
import { WrapperCartaoCredito } from "../payment/Payment.styles";
import { NotificationContext } from "../../context/NotificationContext";

const PaymentCartao = ({ onCardData }) => {
    const [allDataFilled, setAllDataFilled] = useState(false);
    const [cardNumber, setCardNumber] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [cvv, setCVV] = useState("")
    const [cardName, setCardName] = useState("");
    const {addNotification} = useContext(NotificationContext)

    // Verificar se todos os campos estão preenchidos
    useEffect(() => {
        const checkDataFilled = () => {
            setAllDataFilled(
                cardNumber.trim() !== "" && 
                dueDate.trim() !== "" && 
                cvv.trim() !== "" //&& 
                // cardName.trim() !== ""
            );
        };

        checkDataFilled();
    }, [cardNumber, dueDate, cvv, cardName]); // Recalcular sempre que um campo mudar

    const handleSubmit = () => {
        const cardData = {
            cardNumber,
            dueDate,
            cvv,
            cardName,
        };
        onCardData(cardData);
    };

    // Função para formatar a data de validade
    const formatDueDate = (value) => {
        // Remover tudo que não for número
        let numbersOnly = value.replace(/\D/g, '');

        // Limitar o número de caracteres para no máximo 6
        if (numbersOnly.length > 6) {
            numbersOnly = numbersOnly.slice(0, 6);
        }

        // Adicionar barra (/) após o mês
        if (numbersOnly.length >= 3) {
            numbersOnly = numbersOnly.slice(0, 2) + '/' + numbersOnly.slice(2, 6);
        }

        return numbersOnly;
    };

    const handleDueDateChange = (e) => {
        const formattedDate = formatDueDate(e.target.value);
        setDueDate(formattedDate);
    };

    return (
        <WrapperCartaoCredito>
            <div className="card">
                <span className="title">Informações do Cartão</span>
                <input
                    type="text"
                    placeholder="Número do Cartão"
                    value={cardNumber}
                    onChange={(e) => setCardNumber(e.currentTarget.value)}
                />
                <div className="line">
                    <input
                        type="text"
                        placeholder="Data de Validade (MM/AAAA)"
                        value={dueDate}
                        onChange={handleDueDateChange}
                    />
                    <input
                        type="text"
                        placeholder="CVV"
                        value={cvv}
                        onChange={(e) => setCVV(e.currentTarget.value)}
                    />
                </div>
                <input
                    type="text"
                    placeholder="Nome no Cartão"
                    value={cardName}
                    onChange={(e) => setCardName(e.currentTarget.value)}
                />
            </div>
            {allDataFilled ? (
                <span className="button" onClick={handleSubmit}>
                    Realizar Pagamento
                </span>
            ) : (
                <span className="button deactived" onClick={() => addNotification("Por favor, preencha todos os dados.", "info")}>Realizar Pagamento</span>
            )}
        </WrapperCartaoCredito>
    );
};

export default PaymentCartao;
