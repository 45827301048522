import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import NsfwDetection from '../../hooks/NsfwDetection';
import { FaX } from 'react-icons/fa6';
import theme from '../../theme';

const ImageUpload = ({ initialImages = [], images, setImages }) => {
    const [model, setModel] = useState(null);

    useEffect(() => {
        const loadModel = async () => {
        const nsfwjs = window.nsfwjs;
        if (nsfwjs) {
            try {
                const loadedModel = await nsfwjs.load("/models/");
                setModel(loadedModel);
            } catch (error) {
                console.error("Erro ao carregar o modelo:", error);
            }
        } else {
            console.error("nsfwjs não foi encontrado!");
        }
        };

        loadModel();
    }, []);

    const handleAddImages = async (event) => {
        const selectedFiles = Array.from(event.target.files);
        const remainingSlots = 20 - images.length;
        const validImages = [];
        const filesToProcess = selectedFiles.slice(0, remainingSlots);
        if (remainingSlots <= 0) return alert("Você já atingiu o limite de 20 imagens.");
        if (!model) return alert("Modelo NSFW ainda não carregado. Tente novamente.");

        for (const file of filesToProcess) {
        try {
            const isSafe = await NsfwDetection(file, model);
            if (!isSafe) continue;
            validImages.push({
            id: uuidv4(),
            url: URL.createObjectURL(file),
            file: file,
            });
        } catch (error) {
            console.error("Erro ao verificar a imagem:", error);
        }
        }

        if (validImages.length > 0) setImages((prevImages) => [...prevImages, ...validImages]);
        if (selectedFiles.length > remainingSlots) return alert("Somente as primeiras imagens foram carregadas. O limite é 20.");
    };

    const handleRemoveImage = (id) => {
        setImages((prevImages) => prevImages.filter((image) => image.id !== id));
    };

    const moveImage = (index, direction) => {
        setImages((prevImages) => {
        const newImages = [...prevImages];
        const targetIndex = index + direction;

        // Verifica se o novo índice está dentro dos limites
        if (targetIndex < 0 || targetIndex >= newImages.length) return newImages;

        // Troca a posição das imagens
        [newImages[index], newImages[targetIndex]] = [newImages[targetIndex], newImages[index]];
        return newImages;
        });
    };

    const Wrapper = styled.div`
        display: flex;
        justify-content: ${images.length === 0 ? "center" : "flex-start"};
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 10px;
    `;

    return (
        <div className="image-selection" style={{ width: "100%" }}>
        <Wrapper>
            {images.map((image, index) => (
            <div
                key={image.id}
                style={{
                position: "relative",
                width: "24%",
                paddingBottom: "24%",
                overflow: "hidden",
                borderRadius: "5px",
                backgroundImage: `url("${image.url}")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                }}
            >
                <span
                onClick={() => handleRemoveImage(image.id)}
                style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    width: "24px",
                    height: "24px",
                    background: "rgba(255, 0, 0, 0.8)",
                    color: "white",
                    borderRadius: "50%",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                >
                <FaX />
                </span>
                {/* Arrows to Move */}
                <div
                style={{
                    position: "absolute",
                    bottom: "5px",
                    left: "0",
                    right: "0",
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                }}
                >
                <button
                    onClick={() => moveImage(index, -1)} // Move left
                    disabled={index === 0}
                    style={{
                    background: index === 0 ? "#c6c6c6" : theme.fontColor.green,
                    color: index === 0 ? "gray" : "white",
                    border: "none",
                    borderRadius: "5px",
                    padding: "5px",
                    cursor: index === 0 ? "not-allowed" : "pointer",
                    }}
                >
                    ←
                </button>
                <button
                    onClick={() => moveImage(index, 1)} // Move right
                    disabled={index === images.length - 1}
                    style={{
                    background: index === images.length - 1 ? "#c6c6c6" : theme.fontColor.green,
                    color: index === images.length - 1 ? "gray" : "white",
                    border: "none",
                    borderRadius: "5px",
                    padding: "5px",
                    cursor: index === images.length - 1 ? "not-allowed" : "pointer",
                    }}
                >
                    →
                </button>
                </div>
            </div>
            ))}
            <label
            style={{
                display: images.length !== 20 ? "flex" : "none",
                alignSelf: "center",
                justifyContent: "center",
                width: "23%",
                background: theme.fontColor.green,
                color: "white",
                borderRadius: "5px",
                cursor: "pointer",
                position: "relative",
            }}
            >
            <input
                value={""}
                type="file"
                multiple
                accept="image/*"
                onChange={handleAddImages}
                disabled={images.length >= 20}
                style={{
                opacity: 0,
                cursor: "pointer",
                width: "0%",
                height: "0%",
                }}
            />
            <span
                style={{
                fontSize: "1.2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                }}
            >
                <span
                style={{
                    padding: "5px 20px",
                    cursor: "pointer",
                }}
                >
                Carregar
                </span>
            </span>
            </label>
        </Wrapper>
        </div>
    );
};

export default ImageUpload