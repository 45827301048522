import { useContext, useEffect, useState } from "react";
import WrapperMyData from "../../views/my-data/MyData.styles";
import NavBar from "../nav-bar/NavBar";
import { AuthContext } from "../../context/AuthContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../hooks/firebase";
import MyProperty from "../my-property/MyProperty";
 
const ContainerMyProperties = () => {
    const [properties, setProperties] = useState([])
    const [filteredProperties, setFilteredProperties] = useState([]);
    const [searchContext, setSearchContext] = useState("");
    const {currentUser} = useContext(AuthContext)

    const handleKeyDown = (event) => {
        const searchValue = event.target.value.toLowerCase();
        const searchTerms = searchValue.split(' ').filter(term => term.trim() !== '');
        setSearchContext(searchValue);

        const filtered = properties.filter(property => {
            return searchTerms.every(term => 
                property.street.toLowerCase().includes(term) ||
                property.number.toLowerCase().includes(term) ||
                property.neightboard.toLowerCase().includes(term) ||
                property.city.toLowerCase().includes(term) ||
                property.state.toLowerCase().includes(term)
            );
        });

        setFilteredProperties(filtered);
    };

    useEffect(() => {
        const fetchProperties = async () => {
            const propertiesCollection = collection(db, 'properties');
            const q = query(
                propertiesCollection, 
                where("ownerId", "==", currentUser)
            );
            const propertiesSnapshot = await getDocs(q);
            
            const propertiesList = propertiesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            const filteredProperties = propertiesList.filter(property => property.deletedAt === null);

            setProperties(filteredProperties)
            setFilteredProperties(filteredProperties);
        };

        fetchProperties().catch(error => {
            console.error("Erro ao buscar propriedades:", error);
        });
    }, [])

    
    return <WrapperMyData nothing={properties?.length === 0}>
        <NavBar />
        <div className="container-c">
            {properties.length > 0 ?
                <>
                    <div className="search-box">
                        <span className="title">Buscar</span>
                        {/**<input type="text" onChange={handleKeyDown} placeholder="Ex: Condomínio Gaivota 2" /> */}
                        <input type="text" onChange={handleKeyDown} placeholder="Ex: Condomínio Gaivota 2" />
                    </div>
                    <div className="properties">
                        {properties !== null &&  
                            filteredProperties.map((property, index) => {
                                return <MyProperty
                                    key={index+"property-element"}
                                    data={{...property, currentUser}}
                                />
                            })
                        }
                    </div>
                </>    
                :
                <div>
                    <span className="nothing">Nenhum anúncio encontrado no momento. Que tal começar a divulgar o seu imóvel e alcançar futuros moradores? Publique agora e dê o primeiro passo para concretizar essa oportunidade.</span>
                </div>
            }
        </div>
    </WrapperMyData>
}

export default ContainerMyProperties