import styled from "styled-components";
import theme from "../../theme";

const SBlogHome = styled.div`
    .banner {
        background-color: black;
        height: 500px;
        margin-bottom: 0;

        img {
            width: 100%;
            z-index: 2;

            &.bannerBlog {
                height: 0px;
                width: 0px;
                opacity: 0;
                transition-property: opacity;
                transition-duration: 0.2s;
            }

            &.bannerBlog.active {
                width: 100%;
                height: 100%;
                opacity: 0.5;
            }
        }

        .callToAction {
            background-color: ${theme.backgroundColor.purple};
            color: ${theme.fontColor.green};
            text-decoration: none;
            padding: 8px 45px;
            border-radius: 7px;
            text-align: center;
            font-size: 25px;
            margin-bottom: 30px;
        }
    }

    .blogs {
        padding: 30px 18%;
        padding-top: 60px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .primary,
        .secondary {
            width: 48%;
            display: flex;
            flex-direction: column;

        }

        .title:nth-child(1) {
            color: ${theme.fontColor.black3};
            font-size: 1.5rem;
            margin-bottom: 5px;
            width: 100%;
        }

        .primary {
            img {
                width: 100%;
                border-radius: 20px;
            }

            .title {
                color: ${theme.fontColor.black2};
                font-size: 1.3rem;
                margin-bottom: 10px;
                font-family: ${theme.fontFamily.bold};
            }

            .snippet {
                color: ${theme.fontColor.black8};
                font-size: 0.93rem;
                margin-bottom: 25px;
            }

            .readMore {
                color: ${theme.fontColor.black};
                font-size: 0.93rem;
                text-decoration: none;
                display: flex;
                align-items: center;
            }
        }

        .secondary {
            gap: 30px;

            .blog {
                display: flex;
                flex-direction: row;
                gap: 20px;

                .img {
                    width: 37%;
                }

                img {
                    width: 100%;
                    border-radius: 20px;
                }

                .infos {
                    width: 61%;
                    display: flex;
                    flex-direction: column;

                    .title {
                        color: ${theme.fontColor.black2};
                        font-size: 1rem;
                        font-family: ${theme.fontFamily.bold};
                    }

                    .snippet {
                        color: ${theme.fontColor.black8};
                        font-size: 0.85rem;
                        margin-bottom: 18px;
                    }

                    .readMore {
                        color: ${theme.fontColor.black};
                        font-size: 0.85rem;
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }

    /** RESPONSIVIDADE APENAS PARA MOBILE_2 */
    @media (max-width: ${theme.breackpoint.mobile_2}) {
        .blogs {
            flex-direction: column;

            .primary,
            .secondary {
                width: 100%;
            }

            .secondary {
                margin-top: 20px;
                .img {
                    width: 100% !important;
                    img {
                    width: 100%;
                }
                }
                .blog {
                    flex-direction: column;

                    .infos {
                    width: 100%;
                }
                }

                
            }
        }
    }

    /** RESPONSIVIDADE PARA TABLETS E TELAS MÉDIAS */
    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        .blogs {
            padding: 20px 10%;
        }
    }

    /** RESPONSIVIDADE PARA LAPTOPS E TELAS GRANDES */
    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        .blogs {
            padding: 25px 12%;

            .title {
                font-size: .85rem !important;
            }

            .snippet {
                font-size: .75rem !important ;
            }

            .readMore{
                font-size: .7rem !important ;
            }
        }
    }
`;

export default SBlogHome;
