import styled from "styled-components";
import theme from "../../theme"

const WrapperPlans = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 8.855%;
    padding-top: 60px;
 
    h1 {
        font-size: 3.5rem;
        margin: 0;
        color: ${theme.fontColor.green};
        font-family: ${theme.fontFamily.bold};
    }

    h3 {
        margin-top: 0px;
        margin-bottom: 90px;
        font-size: 1.7rem;
        font-family: ${theme.fontFamily.light};

        .bold {
            font-family: ${theme.fontFamily.medium};
        }
    }

    .plans {
        display: flex;
        justify-content: center;
        gap: 30px;
        width: 100%;
    }
 
    @media (max-width: ${theme.breackpoint.mobile_2}) {
        margin-bottom: 50px;
        h1 {
            text-align: center;
            font-size: 2.4rem;
        }

        h3 {
            text-align: center;
            margin-bottom: 40px;
            font-size: 1.4rem;
        }
        
        .plans {
            flex-direction: column;
            gap: 60px;
        }
    }
    
    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        .plans {
            flex-wrap: wrap;
            gap: 40px;
        }
    }
    
    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        padding: 30px 2.855%;
    }
    
    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        padding: 30px 5.855%;
    }
    
    @media (max-width: ${theme.breackpoint.bigScreens}) and (min-width: ${theme.breackpoint.largeScreens}) {
        
    }
    
`

export default WrapperPlans