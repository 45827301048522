import { signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { auth } from "./firebase";

const loginUser = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    return true
      
  } catch (error) {
    console.error("Erro ao fazer login:", error);
  }
};

export default loginUser;
