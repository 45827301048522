import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../context/AuthContext";
import fetchUserData from "../../hooks/getUserData";
import WrapperMyData from "../../views/my-data/MyData.styles";
import NavBar from "../nav-bar/NavBar";
import { collection, doc, getDoc, getDocs, or, query, where } from "firebase/firestore";
import { db } from "../../hooks/firebase";
import ChatCard from "../chat_card/ChatCard";
 
const MyChats = () => {
    const [data, setData] = useState(null);
    const { currentUser, userData } = useContext(AuthContext);

    const fetchMyChats = async () => {
      try {
        const user = currentUser;
        const userChatsQuery = query(
          collection(db, "chats"),
          or(
            where("ownerId", "==", user),
            where("participantId", "==", user)
          )
        );
    
        const querySnapshot = await getDocs(userChatsQuery);
        const chats = await Promise.all(
          querySnapshot.docs.map(async (r) => {
            const tempData = r.data();
            let userRef;
            if (tempData.ownerId === currentUser) {
              userRef = doc(db, "users", tempData.participantId);
            } else {
              userRef = doc(db, "users", tempData.ownerId);
            }
    
            const result = await getDoc(userRef);
            tempData.participantData = result.data();
    
            return {
              id: r.id,
              ...tempData,
            };
          })
        );

        return chats;
      } catch (error) {
        throw error;
      }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                //await fetchUserData(currentUser);
                const chats = await fetchMyChats();
                setData(chats);
            } catch (error) {
                console.error('Erro ao buscar dados do usuário ou chats:', error);
            }
        };

        if (data === null) {
            fetchData();
        }
    }, []);

    return (
        <WrapperMyData nothing={data?.length === 0}>
            <NavBar />
            <div className="container-chats">
                {data && data.map((item) => (
                    <span key={item.id}>
                        <ChatCard data={item} />
                    </span>
                ))}
                {data?.length === 0 && <div className="">
                    <span className="nothing-message">Nenhum chat no momento, continue navegando pelo site ou espere seus futuros inquelinos :)</span>
                </div>}
            </div>
        </WrapperMyData>
    );
}

export default MyChats;
