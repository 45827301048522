import styled from "styled-components";
import theme from "../../theme";

const WrapperViewProperty = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    .actions {
        margin: 0 12%;
        margin-top: 20px;
        display: flex;
        align-self: flex-start;
        flex-direction: column;

        .message {
            color: rgb(85, 85, 85);
            margin-left: 5px;
            font-size: .9rem;
        }

        .button {
            display: flex;
            align-items: center;
            gap: 4px;
            padding: 4px 8px;
            border-radius: 10px;
            border: 2px solid ${theme.fontColor.green};
            align-self: flex-start;
            color: ${theme.fontColor.green};
            cursor: pointer;
            user-select: none;
            transition-property: background, color;
            transition-duration: 300ms, 300ms; 

            svg {
                font-size: 1.7rem;
            }

            &.actived{
                background-color: ${theme.fontColor.green};
                color: white;
            }
        
        }
    }


    .infos {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 12%;

        .location {
            width: 100%;
            font-size: 1.2rem;
            margin: 20px 0px;
        }

        .left {
            width: 64%;
            display: flex;
            flex-direction: column;

            .infos-container {
                display: flex;
                width: 70%;
                justify-content: space-between;
                margin-bottom: 30px;

                .info {
                    width: 25%;
                    display: flex;
                    align-items: center;
                    gap: 2px;
                    font-size: 1.1rem;

                    svg {
                        font-size: 1.3rem;
                        margin-right: 7px;
                    }
                }
            }

            .price {
                font-size: 2.2rem;
                font-family: ${theme.fontFamily.bold};
            }

            .extras {
                font-size: 1.1rem;
                margin-bottom: 20px;
            }

            .about {
                display: flex;
                flex-wrap: wrap;
                gap: 5px;
                margin-top: 15px;

                .spec {
                    width: calc(31% - 5px);
                    margin-top: 5px;
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    input {
                        display: none;
                    }

                    label {
                        position: relative;
                        padding-left: 35px;
                        font-size: 1rem;
                        user-select: none;

                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 24px;
                            height: 24px;
                            border: 1px solid black;
                            border-radius: 50%;
                            background-color: white;
                            transition: all 0.3s ease; 
                        }
                    }

                    input[type="radio"]:checked + label::before {
                        background-color: ${theme.fontColor.green};
                    }
                }
            }
        }

        .advertiser {
            width: 27.5%;
            padding: 10px;
            border-radius: 10px;
            border: 1px solid #9e9e9e;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-self: flex-start;
            position: relative;

            .title {
                width: 100%;
                border: 1px solid #9e9e9e;
                padding: 1px 0px;
                text-align: center;
                font-family: ${theme.fontFamily.bold};
                color: ${theme.fontColor.green};
                border-radius: 10px;
                font-size: 1.7rem;
            }

            .user {
                display: flex;
                margin: 20px 5%;
                gap: 20px;
                align-self: flex-start;

                img {
                    width: 70px;
                    height: 70px;
                    border-radius: 100px;
                    background-color: #9e9e9e;
                }

                .user-icon {
                    width: 70px;
                    height: 70px;
                    border-radius: 100px;
                    background-color: #c3c3c3;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 2rem;
                    color: gray;
                }

                .about {
                    display: flex;
                    flex-direction: column;

                    .name {
                        font-family: ${theme.fontFamily.medium};
                        color: #303030;
                        font-size: 1.2rem;
                    }

                    .function, .creci{
                        font-size: .8rem;
                    }
                }


            }

            .button {
                width: 90%;
                padding: 3px 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                margin-bottom: 10px;
                border-radius: 100px;
                font-size: 1rem;
                cursor: pointer;

                & svg {
                    font-size: 1.3rem;
                }

                &.email{
                    background-color: #01bbb6;
                }

                &.whatsapp{
                    background-color: #00cb00;
                }

                &.chat {
                    background-color: #ff443d;
                    
                }
            }

            .info {
                width: 85%;
                align-self: flex-start;
                margin-left: 5%;
                margin-top: 10px;
                font-size: .8rem;
            }

            .report{
                position: absolute;
                bottom: 0;
                right: 0;
                margin-right: 10px;
                margin-bottom: -25px;
                font-family: ${theme.fontFamily.medium};
                cursor: pointer;
                user-select: none;
            }
        }
    }

    .description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% - 24%);
        padding: 0 12%;
        margin-top: 60px;
 
        .title {
            font-size: 1.4rem;
            font-family: ${theme.fontFamily.bold};
        } 

        .content {
            width: 50%;
            font-size: 1.2rem;
            font-family: ${theme.fontFamily.regular};
        }
    }

    .location {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin: 0 auto;
        margin-top: 80px;

        .title {
            font-family: ${theme.fontFamily.medium};
        }

        .address {
            display: flex;
            align-items: center;
            gap: 10px;
            font-family: ${theme.fontFamily.regular};

            svg {
                margin-top: -2px;
                font-size: 1.3rem;
            }
        }

        iframe {
            width: 100%;
            margin-top: 10px;
            height: 300px;
            border: none;
            border-radius: 10px;
        }
    }

    .recomendations {
        width: 100%;
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        margin-bottom: 80px;
        .actions {
            margin: 0 8%;
            margin-top: 20px;
        }
        
        .infos {
            margin: 0 8%;
            flex-wrap: wrap;

            .location {
                font-size: 1.2rem;
            }

            .left {
                width: 100%;

                .infos-container {
                    width: 85%;
                    flex-wrap: wrap;
                    gap: 20px;

                    .info {
                        width: calc(49% - 20px);
                        font-size: 1.1rem;

                        svg {
                            font-size: 1.3rem;
                        }
                    }
                }

                .price {
                    font-size: 1.9rem;
                }

                .extra {
                    font-size: 1rem;
                }

                .about {
                    .spec {
                        width: 49%;

                        label {
                            font-size: 1rem;
                            margin-bottom: 5px;

                            &::before {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }
            }

            .advertiser {
                width: 100%;
                margin-top: 40px;


                .title {
                    font-size: 1.4rem;
                }

                .user {

                    img {
                        width: 100px;
                        height: 100px;
                    }

                    .about {
                        .name {
                            font-size: 1rem;
                        }
                        .function, .creci{
                            font-size: .8rem;
                        }
                    }
                }

                .button {
                    font-size: 1rem;
                }

                .info {
                    font-size: .8rem;
                }
            }
        }

        .description {
            width: 75%;
            
            .title {
                font-size: 1.4rem;
            }
            .content {
                font-size: .97rem;
            }
        }

        .location {
            width: 75%;

            .address, .title {
                font-size: .9rem;
            }
        }

        .recomendations {
            width: 85%;
        }   
    }
    
    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        .actions {
            margin: 0 8%;
            margin-top: 20px;
        }
        
        .infos {
            margin: 0 8%;
            flex-wrap: wrap;

            .location {
                font-size: 1rem;
            }

            .left {
                width: 100%;
                .infos-container {
                    width: 85%;

                    .info {
                        font-size: .9rem;

                        svg {
                            font-size: 1.1rem;
                        }
                    }
                }

                .price {
                    font-size: 1.7rem;
                }

                .extra {
                    font-size: .9rem;
                }

                .about {
                    .spec {
                        label {
                            font-size: .8rem;
                            margin-bottom: 5px;

                            &::before {
                                width: 21px;
                                height: 21px;
                            }
                        }
                    }
                }
            }

            .advertiser {
                width: 100%;
                margin-top: 40px;


                .title {
                    font-size: 1.4rem;
                }

                .user {

                    img {
                        width: 100px;
                        height: 100px;
                    }

                    .about {
                        .name {
                            font-size: 1rem;
                        }
                        .function, .creci{
                            font-size: .8rem;
                        }
                    }
                }

                .button {
                    font-size: 1rem;
                }

                .info {
                    font-size: .8rem;
                }
            }
        }

        .description {
            width: 75%;
            
            .title {
                font-size: 1.15rem;
            }
            .content {
                font-size: .87rem;
            }
        }

        .location {
            width: 75%;

            .address, .title {
                font-size: .9rem;
            }
        }

        .recomendations {
            width: 100%;
        }   
    }
    
    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        .actions {
            margin: 0 8%;
            margin-top: 20px;
        }
        
        .infos {
            margin: 0 8%;

            .location {
                font-size: 1rem;
            }

            .left {
                .infos-container {
                    width: 85%;

                    .info {
                        font-size: .9rem;

                        svg {
                            font-size: 1.1rem;
                        }
                    }
                }

                .price {
                    font-size: 1.7rem;
                }

                .extra {
                    font-size: .9rem;
                }

                .about {
                    .spec {
                        label {
                            font-size: .8rem;
                            margin-bottom: 5px;

                            &::before {
                                width: 21px;
                                height: 21px;
                            }
                        }
                    }
                }
            }

            .advertiser {

                .title {
                    font-size: 1.2rem;
                }

                .user {

                    .about {
                        .name {
                            font-size: .9rem;
                        }
                        .function, .creci{
                            font-size: .7rem;
                        }
                    }
                }

                .button {
                    font-size: .85rem;
                }

                .info {
                    font-size: .7rem;
                }
            }
        }

        .description {

            .title {
                font-size: 1.15rem;
            }
            .content {
                font-size: .87rem;
            }
        }

        .location {

            .address, .title {
                font-size: .9rem;
            }
        }

        .recomendations {
            width: 100%;
        }   
    }
    
    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        
        .actions {
            margin: 0 10%;
            margin-top: 20px;
        }

        .infos {
            margin: 0 10%;

            .location {
                font-size: 1.1rem;
            }

            .left {
                .infos-container {
                    width: 85%;

                    .info {
                        font-size: 1rem;

                        svg {
                            font-size: 1.2rem;
                        }
                    }
                }

                .price {
                    font-size: 1.9rem;
                }

                .extra {
                    font-size: 1rem;
                }

                .about {
                    .spec {
                        label {
                            font-size: .9rem;

                            &::before {
                                width: 22px;
                                height: 22px;
                            }
                        }
                    }
                }
            }

            .advertiser {
                .title {
                    font-size: 1.5rem;
                }

                .user {

                    .about {
                        .name {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }

        .description {
            .title {
                font-size: 1.25rem;
            }
            .content {
                font-size: 1.07rem;
            }
        }

        .recomendations {
            width: 90%;
        }
    }
    
    @media (max-width: ${theme.breackpoint.bigScreens}) and (min-width: ${theme.breackpoint.largeScreens}) {
        
        .infos {

        }

        .description {
            .title {
                font-size: 1.3rem;
            }

            .content {
                font-size: 1.05rem;
            }
        }

        .recomendations {
            width: 85%;
        }
    }
    
`

export default WrapperViewProperty