import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import WrapperModal from "../nav-bar/Modal.styles"; // Supondo que o estilo do modal seja o mesmo
import { getModel } from "../../hooks/loadModel"; // Para a detecção NSFW
import NsfwDetection from "../../hooks/NsfwDetection"; // Importar a lógica de NSFW se necessário
import { Link, useNavigate } from "react-router-dom";
import { useContext, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { getAuth, signOut } from "firebase/auth";
import { db, uploadProfileImage } from "../../hooks/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { FaBlog, FaCamera, FaRegClipboard, FaRegHeart } from "react-icons/fa";
import WrapperHeaderMobile from "./HeaderMobile.styles";
import { FaArrowRightToBracket } from "react-icons/fa6";
import { SlArrowRight } from "react-icons/sl";
import { PiUserCircle } from "react-icons/pi";
import { BsBoxArrowLeft, BsHouse } from "react-icons/bs";
import { LuClock3 } from "react-icons/lu";
import { IoChatbubbleOutline, IoHome, IoSearch } from "react-icons/io5";
import { IoMdSpeedometer } from "react-icons/io";
import { CiText } from "react-icons/ci";
import { GoPlusCircle } from "react-icons/go";
import { RxHamburgerMenu } from "react-icons/rx";

const HeaderMobile = () => {
    const navigate = useNavigate();
    const { currentUser, userData, setUserData } = useContext(AuthContext);
    const [show, setShow] = useState(false);
    const [image, setImage] = useState(null); // Imagem para recorte
    const [cropper, setCropper] = useState(null); // Referência ao cropper
    const [cropping, setCropping] = useState(false); // Controle do modal de recorte
    const [isSaving, setIsSaving] = useState(false); // Controle para estado de salvando
    const fileInputRef = useRef(null);

    const logout = () => {
        const auth = getAuth();
        try {
            signOut(auth);
            setShow(!show);
            navigate("/entrar");
        } catch (error) {
            console.error("Erro ao deslogar: ", error);
        }
    };

    const handleToggle = () => {
        setShow(!show);
    };

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result); // Define a imagem no Cropper
                setCropping(true); // Abre o modal para recorte
            };
            reader.readAsDataURL(file);
        }
    };

    const saveCroppedImage = async () => {
        if (cropper) {
            setIsSaving(true);
            cropper.getCroppedCanvas().toBlob(async (blob) => {
                if (blob) {
                    const model = await getModel();
                    const result = await NsfwDetection(blob, model); // Checa conteúdo NSFW
                    if (result) {
                        uploadProfileImage(currentUser, blob).then((result) => {
                            if (result) {
                                const userRef = doc(db, "users", currentUser);
                                updateDoc(userRef, { photoURL: result })
                                    .then(() => {
                                        setUserData((prevUserData) => ({
                                            ...prevUserData,
                                            photoURL: result,
                                        }));
                                        setCropping(false); // Fecha o modal
                                        setIsSaving(false); // Desabilita estado de salvando
                                    })
                                    .catch((error) =>
                                        console.error("Erro ao atualizar Firestore:", error)
                                    );
                            } else {
                                console.error("Falha ao atualizar a imagem de perfil.");
                                setIsSaving(false);
                            }
                        });
                    } else {
                        console.error("Imagem não aprovada pela detecção NSFW.");
                        setIsSaving(false);
                    }
                } else {
                    console.error("Erro ao converter a imagem recortada em um Blob.");
                    setIsSaving(false);
                }
            }, "image/jpeg");
        }
    };

    return (
        <WrapperHeaderMobile>
             <div className={`top${show ? " show" : ""}`}>
                {userData && (
                    <div className="user">
                        {!userData.photoURL ? ( 
                            <div className="user-img" onClick={handleImageClick}>
                                <FaCamera />
                            </div>
                        ) : (
                            <img
                                src={userData.photoURL}
                                alt="Imagem de perfil"
                                onClick={handleImageClick}
                            />
                        )}
                        <span className="name">{userData?.name}</span>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                    </div>
                )}
                {!userData && (
                    <Link to={"/entrar"} onClick={handleToggle}>
                        <div className="info">
                            <FaArrowRightToBracket />
                            Entrar
                        </div>
                        <SlArrowRight />
                    </Link>
                )} 
                {userData && (
                    <Link to={"/meus-dados"} onClick={handleToggle}>
                        <div className="info">
                            <PiUserCircle />
                            Meus dados
                        </div>
                        <SlArrowRight />
                    </Link>
                )}
                <Link to={"/blog"} onClick={handleToggle}>
                    <div className="info">
                        <FaBlog />
                        Blog
                    </div>
                    <SlArrowRight />
                </Link>
                {userData && (
                    <Link to={"/meus-imoveis"} onClick={handleToggle}>
                        <div className="info">
                            <BsHouse />
                            Meus Imóveis
                        </div>
                        <SlArrowRight />
                    </Link>
                )} 
                {userData && (
                    <Link to={"/vistos-por-ultimo"} onClick={handleToggle}>
                        <div className="info">
                            <LuClock3 />
                            Visto por último
                        </div>
                        <SlArrowRight />
                    </Link>
                )}
                {userData && (
                    <Link to={"/notificacoes"} onClick={handleToggle}>
                        <div className="info">
                            <IoChatbubbleOutline />
                            Notificações
                        </div>
                        <SlArrowRight />
                    </Link>
                )}
                <Link to={"/nossos-planos"} onClick={handleToggle}>
                    <div className="info">
                        <IoMdSpeedometer />
                        Turbinar
                    </div>
                    <SlArrowRight />
                </Link>
                {userData && (
                    <Link to={"/meus-imoveis-favoritos"} onClick={handleToggle}>
                        <div className="info">
                            <FaRegHeart />
                            Favoritos
                        </div>
                        <SlArrowRight />
                    </Link>
                )}
                <Link to={"/institucional"} onClick={handleToggle}>
                    <div className="info">
                        <CiText />
                        Institucional
                    </div>
                    <SlArrowRight />
                </Link>
                <Link to={"/meus-termos-de-uso"} onClick={handleToggle}>
                    <div className="info">
                        <FaRegClipboard />
                        Termos de uso
                    </div>
                    <SlArrowRight />
                </Link>
                {userData && (
                    <a href onClick={logout}>
                        <div className="info">
                            <BsBoxArrowLeft />
                            Sair
                        </div>
                        <SlArrowRight />
                    </a>
                )}
            </div>
            <div className="bottom">
                <Link to="/" className="item" onClick={() => setShow(false)}>
                    <IoHome />
                    <span>Início</span>
                </Link>
                <Link to="/buscador" className="item" onClick={() => setShow(false)}>
                    <IoSearch />
                    <span>Buscar</span>
                </Link>
                <Link to="/anunciar" className="item" onClick={() => setShow(false)}>
                    <GoPlusCircle />
                    <span>Anunciar</span>
                </Link>
                <Link to="/meus-chats" className="item" onClick={() => setShow(false)}>
                    <IoChatbubbleOutline />
                    <span>Mensagem</span>
                </Link>
                <div className="item" onClick={() => setShow(!show)}>
                    <RxHamburgerMenu />
                    <span>Menu</span>
                </div>
            </div>

            {cropping && (
                <WrapperModal>
                    <div className="modal-content">
                        <h2>Recorte sua Imagem</h2>
                        <Cropper
                            src={image}
                            style={{ height: 400, width: "100%" }}
                            initialAspectRatio={1}
                            aspectRatio={1}
                            guides={true}
                            viewMode={1}
                            minCropBoxHeight={100}
                            minCropBoxWidth={100}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            onInitialized={(instance) => setCropper(instance)}
                        />
                        <div className="modal-actions">
                            {isSaving ? (
                                <button className="btn-save">Salvando...</button>
                            ) : (
                                <button className="btn-save" onClick={saveCroppedImage}>
                                    Salvar
                                </button>
                            )}
                            <button className="btn-cancel" onClick={() => setCropping(false)}>
                                Cancelar
                            </button>
                        </div>
                    </div>
                </WrapperModal>
            )}
        </WrapperHeaderMobile>
    );
};

export default HeaderMobile;
