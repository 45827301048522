import styled from "styled-components";
import theme from "../../theme";

const WrapperTermsOfUse = styled.div`
    margin: 30px 12%;
    display: flex;
    justify-content: center;
    position: relative;

    .container{
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 60%; 
        margin-left: 50px;
        margin-top: 20px;
        overflow-x: auto;
        height: 60vh;

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 5px;
        }

        .title {
            font-size: 2rem;
            font-family: ${theme.fontFamily.extraBold}
        }

        .body {
            font-size: .955rem;
        }
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        margin-bottom: 300px;

        .container {
            width: 100%;
            margin-left: 0;
            position: relative;
        }
    }
    
    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        margin: 30px 2%;

        .container {
            width: 50%;
        }
    }
    
    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        margin: 30px 8%;

        .container {
            width: 50%;
        }
    }
    
    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        margin: 30px 8%;

        .container {
            width: 50%;
        }
    }
    
    @media (max-width: ${theme.breackpoint.bigScreens}) and (min-width: ${theme.breackpoint.largeScreens}) {
        
    }
`

export default WrapperTermsOfUse