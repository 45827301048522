import styled from "styled-components";
import theme from "../../theme";

const WrapperBlog = styled.div`
    margin: 0 15%;
    display: flex;
    flex-direction: column;

    .post-metadata {
        margin-top: 30px;
        display: flex;
        flex-direction: column;

        .location {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: .9rem;
            gap: 5px;

            span:nth-child(odd){
                color: ${theme.fontColor.green};
            }
            span:last-child{
                color: ${theme.fontColor.gray};
                
            }
        }

        .details{
            margin-top: 10px;
            display: flex;
            gap: 15px;
            font-size: .9rem;
            font-weight: 600;

            .date {
                color: ${theme.fontColor.gray};
                border-right: 2px solid ${theme.fontColor.gray};
                padding-right: 5px;
            }
            .theme {
                color: ${theme.fontColor.green};
            }
            .author{
                color: ${theme.fontColor.gray};
            }
        }

        .title {
            margin-top: 7px;
            font-size: 2.2rem;
            font-weight: 600;
        }

        .share{
            color: ${theme.fontColor.gray};
            font-size: .9rem;
            font-weight: 600;
            margin-top: 20px;
        }
    }

    .body {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .content {
            width: 72%;
            display: flex;
            gap: 10px;
            flex-direction: column;
            font-size: 1.25rem;

            img {
                margin-top: 10px;
                margin-bottom: 40px;
                border-top: 1px solid ${theme.fontColor.gray};
                padding-top: 60px;
                height: 250px;
                width: 75%;
            }
        }

        .another-blogs {
            width: 22%;
            margin-top: 60px;
            display: flex;
            flex-direction: column;
            gap: 30px;

            .title {
                font-size: 1.5rem;
                font-weight: 600;
            }
        }
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        margin: 0 10%;
        margin-bottom: 50px;

        .post-metadata {
            
            .location {
                width: 100%;
                font-size: .80rem;
                gap: 5px;
            }

            .details{
                margin-bottom: 20px;
                font-size: .8rem;
            }

            .title {
                font-size: 1.4rem;
            }
        }

        .body {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            margin-top: 10px;

            .content {
                width: 100%;
                flex-direction: column;
                font-size: 1rem;

                img {
                    height: 180px;
                    width: 100%;
                }
            }

            .another-blogs {
                width: 100%;

                img {
                    border-radius: 10px;
                }
                
                .title {
                    font-size: 1.2rem;
                }
            }
        }
    }      
`

export default WrapperBlog