import styled from "styled-components";
import theme from "../../theme";

const WrapperMyProperties = styled.div`
    margin: 30px 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .container{
        display: flex;
        flex-direction: column;
        width: 55%; 
        gap: 20px;
        margin-left: 50px;
        overflow-y: auto;
        margin-top: 40px;
        height: 60vh;

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 5px;
        }

        .search-box {
            width: 90%;
            display: flex;
            flex-direction: column;
            gap: 13px;

            .title {
                font-size: 1.3rem;
                font-weight: 600;
                margin-left: 20px;
            }

            input {
                outline: none;
                border: #888 1px solid;
                border-radius: 100px;
                padding: 7px 20px;
                font-size: 1.175rem;
            }
        }

        .properties {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }
        
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        margin-bottom: 300px;
        margin: 30px 12%;

        .container {
            width: 100%;
            margin-left: 0;
            position: relative;
        }
    }
    
    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        margin: 30px 2%;

        .container {
            width: 50%;
        }
    }
    
    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        margin: 30px 8%;

        .container {
            width: 50%;
        }
    }
    
    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        margin: 30px 8%;

        .container {
            width: 50%;
        }
    }
    
    @media (max-width: ${theme.breackpoint.bigScreens}) and (min-width: ${theme.breackpoint.largeScreens}) {
        
    }
`

export default WrapperMyProperties