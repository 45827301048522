import React, { useRef, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";

const CustomSelectBox = ({
  options, // Array de opções
  showOptions, // Estado de visibilidade do menu
  setShowOptions, // Função para alterar a visibilidade
  selectboxValue, // Valor selecionado
  handleSelectOption, // Função para lidar com a seleção de uma opção
}) => {
  const selectBoxRef = useRef(null); // Referência ao elemento do select-box

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectBoxRef.current && !selectBoxRef.current.contains(event.target)) {
        setShowOptions(false); // Fecha o menu ao clicar fora
      }
    };

    // Adiciona o evento de clique fora
    document.addEventListener("mousedown", handleClickOutside);

    // Remove o evento ao desmontar
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowOptions]);

  return (
    <div className="select-box" ref={selectBoxRef}>
      <div className="custom-selectbox">
        <span
          className="value"
          onClick={() => setShowOptions(!showOptions)}
        >
          {selectboxValue}
        </span>
        <div className={`options${showOptions ? " show" : ""}`}>
          {options.map((option, index) => (
            <span
              key={index + option.option}
              className={`option${
                selectboxValue === option.option ? " selected" : ""
              }`}
              onClick={() => handleSelectOption(option.option)}
            >
              {option.option}
            </span>
          ))}
        </div>
      </div>
      <IoIosArrowDown
        className={showOptions && "actived"}
        onClick={() => setShowOptions(!showOptions)}
      />
    </div>
  );
};

export default CustomSelectBox;
