import styled from "styled-components";
import theme from "../../theme";

const WrapperHome = styled.div`


    @media (max-width: ${theme.breackpoint.mobile_2}) {
        margin-bottom: 80px;
    }
    
    
`

export default WrapperHome