const NsfwDetection = async (imageFile, model) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onloadend = () => {
        const imgElement = new Image();
        imgElement.src = reader.result;
        imgElement.onload = async () => {
          try {
            const result = await model.classify(imgElement);
            const neutralObject = result.find(obj => obj.className === "Neutral");
            const isSafe = neutralObject.probability >= 0.50;
            resolve(isSafe)
          } catch (error) {
            console.error('Erro ao classificar a imagem:', error);
            reject(error);
          }
        };
  
        imgElement.onerror = () => {
          console.error('Erro ao carregar a imagem');
          reject(new Error('Erro ao carregar a imagem'));
        };
      };
  
      reader.readAsDataURL(imageFile);
    });
  };
  
export default NsfwDetection;