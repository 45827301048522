import styled from "styled-components";
import theme from "../../theme";

const WrapperModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    
    .modal-content {
        background: white;
        padding: 20px;
        border-radius: 10px;
        width: 90%;
        max-width: 500px;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        position: relative;
    }

    .modal-actions {
        margin-top: 15px;
        display: flex;
        justify-content: flex-end;
        gap: 15px;
    }

    .btn-save,
    .btn-cancel {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
    }

    .btn-save {
        background-color: ${theme.fontColor.green};
        color: white;
    }

    .btn-cancel {
        background-color: #f44336;
        color: white;
    }


`

export default WrapperModal