import styled from "styled-components";
import theme from "../../theme";

const WrapperSwitchPassword = styled.div`
    margin: 30px 12%;
    display: flex;
    justify-content: center;
    position: relative;

    .container{
        display: flex;
        flex-direction: column;
        width: 60%; 
        gap: 20px;
        margin-left: 50px;
        margin-top: 50px;

        .line {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .title {
                font-size: 1.2rem;
                margin-left: 10px;
                font-family:  ${theme.fontFamily.regular};
                color: #171717;
            }

            .input-box{
                display: flex;
                border-radius: 100px;
                justify-content: space-between;
                width: 95%;
                padding: 7px 2.5%;
                background-color: #e4e4e4;

                input{
                    width: 88%;
                    border: none;
                    outline: none;
                    background-color: rgba(0,0,0,0);
                    font-size: 1.275rem;
                }

                .sufix {
                    font-family:  ${theme.fontFamily.regular};
                    user-select: none;
                    cursor: pointer;
                }
            }
        }

        .button{
            font-size: 1.2rem;
            color: white;
            background-color: #01bbb6;
            align-self: flex-start;
            padding: 3px 30px;
            border-radius: 100px;
            margin-top: 50px;
        }
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        margin-bottom: 300px;

        .container {
            width: 100%;
            margin-left: 0;
            position: relative;
        }
    }
    
    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        margin: 30px 2%;

        .container {
            width: 50%;
        }
    }
    
    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        margin: 30px 8%;

        .container {
            width: 50%;
        }
    }
    
    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        margin: 30px 8%;

        .container {
            width: 50%;
        }
    }
    
    @media (max-width: ${theme.breackpoint.bigScreens}) and (min-width: ${theme.breackpoint.largeScreens}) {
        
    }
`

export default WrapperSwitchPassword