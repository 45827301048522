import styled from "styled-components";
import { MdContentCopy } from "react-icons/md";
import { IoBedOutline } from "react-icons/io5";
import { FaShower } from "react-icons/fa";
import { IoCarOutline } from "react-icons/io5";
import { PiHeart } from "react-icons/pi";
import theme from './../../theme';
import { useNavigate } from "react-router-dom";
import { div } from "framer-motion/client";

const WrapperHouseAdvertisementCard = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 13px;
    border: 2px solid #dfe0e6;
    display: flex;
    flex-direction: column;

    .img-container {
        width: 100%;
        height: 230px;
        cursor: pointer;
        overflow: hidden; /* Garante que a imagem não ultrapasse o contêiner */
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover; /* Garante que a imagem preencha o contêiner sem distorção */
            border-top-right-radius: 13px;
            border-top-left-radius: 13px;
            display: block;
        }
    }

    .property-info {
        display: flex;
        flex-direction: column;
        padding: 17px;
        

        .property-title {
            font-weight: 600;
            cursor: pointer;
            font-size: 1rem !important;
            font-family: ${theme.fontFamily.regular};
            color: #2b353f;
        }

        .property-road {
            color: #6f7688;
            margin: 5px 0px;
            font-weight: 600;
            font-size: .845rem !important;
        }

        .property-space {
            margin-bottom: 27px;
        }

        .room-info{
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .info {
                width: 33%;
                display: flex;
                align-items: center;
                gap: 2px;
                font-size: .875rem !important;
                font-family: ${theme.fontFamily.medium};
                color: #586880;

                &.alqueiro {
                    width: 100%;
                }

                svg {
                    width: 1.2rem !important;
                    height: 1.2rem !important;
                }

                &:nth-child(1){
                    justify-content: flex-start;
                }

                &:nth-child(2){
                    justify-content: center;
                }

                &:nth-child(3){
                    justify-content: flex-end;

                    transform: translateX(-20px);
                }

                &:nth-child(4){
                    justify-content: flex-start;
                    margin-top: 10px;
                }
            }
        }

        .price-action {
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            justify-self: flex-end;

            .price-container{
                display: flex;
                flex-direction: column;

                .base-price{
                    font-family: ${theme.fontFamily.medium};
                    font-weight: 600;
                    font-size: 1rem !important;
                }

                .price {
                    color: #5e6578;
                    font-family: ${theme.fontFamily.medium};
                    font-size: .875rem !important;
                    font-weight: 600;
                }
            }

            .action {

                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    border-radius: 15px;
                    border: 2px solid #dfe0e6;
                    padding: 10px;
                    color: ${theme.fontColor.green};
                    font-size: 30px;
                    transition: background .3s, color .3s, border .3s;
                    cursor: pointer;

                    &:hover{
                        background-color: ${theme.fontColor.green};
                        border-color: ${theme.fontColor.green};
                        color: white;
                    }
                }
            }
        }
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        width: 100%;

        .property-info {

            .room-info {
                margin-top: 0;

                .info {
                    width: 25%;
                    margin-top: 20px !important;
                    font-size: 20px;
                    justify-content: flex-start !important;

                    svg {
                        font-size: 20px;
                    }

                    &:nth-child(1){
                        justify-content: flex-start;
                    }

                    &:nth-child(2){
                        justify-content: flex-end;
                        svg {
                            font-size: 22px;
                        }
                    }

                    &:nth-child(3){ 
                        transform: none;
                        justify-content: flex-start;
                    }

                    &:nth-child(4){
                        justify-content: flex-end;
                    }
                }
            }
        }
    }

    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.mobile_2}) {
        
        .img-container {
            height: 190px;
        }
        
        .property-info {

        .property-title {
            font-size: .75rem !important;
        }

        .room-info {

            .info {
                width: 49%;
                justify-content: flex-start !important;
                transform: none !important;
                font-size: .85rem !important;

                &:nth-child(2){
                    
                    svg {
                        margin-left: 3px;
                    }
                }
            }
        }

        .price-action {
            font-size: 1rem;

            .price-container {
                .base-price {
                    font-size: .9rem !important;
                }
                .price {
                    font-size: .9rem !important;
                }
            }

            .action {
                svg {
                    font-size: 1.35rem;
                }
            }
        }
        }
    } 

    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        
        .property-info {

            .property-title {
                font-size: .85rem !important;
            }

            .room-info {

                .info {
                    width: 49%;
                    justify-content: flex-start !important;
                    transform: none !important;
                    font-size: .85rem !important;

                    &:nth-child(2){
                        
                        svg {
                            margin-left: 3px;
                        }
                    }
                }
            }

            .price-action {
                font-size: 1rem;

                .price-container {
                    .base-price {
                        font-size: .9rem !important;
                    }
                    .price {
                        font-size: .9rem !important;
                    }
                }

                .action {
                    svg {
                        font-size: 1.35rem;
                    }
                }
            }
        }
    }
`

const HouseAdvertisementCard = props => { 
    const navigate = useNavigate()

    const goToAbout = () => {
        navigate(props.uri)
    }

    return (
        <WrapperHouseAdvertisementCard>
            <div className="img-container">
                <img src={props.img} alt={props.title} onClick={goToAbout} />
            </div>
            <div className="property-info">
                <span className="property-title" onClick={goToAbout}>{props.title}</span>
                <span className="property-road">{props.road}</span>
                <div className="room-info">
                    {props.square_meter &&
                        <span className="info">
                            <MdContentCopy />
                            {props.square_meter} m²
                        </span>
                    }
                    {["Área Comercial", "Terreno", "Terreno Em Condomínio", "Terreno Comercial"].includes(props.road) &&
                        <span className="info">
                            <MdContentCopy />
                            {props.square_local} m²
                        </span>
                    }
                    {props.road === "Sítio/Fazenda" &&
                        <span className="info alqueiro">
                            <MdContentCopy />
                            {props.alqueiro} alqueiro
                        </span>
                    }
                    {props.bedrooms && 
                        <span className="info">
                            <IoBedOutline />
                            {props.bedrooms}
                        </span>
                    }
                    {props.showers &&
                        <span className="info">
                            <FaShower />
                            {props.showers}
                        </span>
                    }
                    {props.garage && 
                        <span className="info">
                            <IoCarOutline />
                            {props.garage}
                        </span>
                    }
                </div>
                {["Área Comercial", "Galpão/Armazém", "Terreno", "Terreno Em Condomínio", "Terreno Comercial", "Sítio/Fazenda", "Prédio Comercial"].includes(props.road) &&
                    <div className="property-space"></div>
                }
                <div className="price-action">
                    <div className="price-container">
                        <span className="base-price">{props.base_price}</span>
                    </div>
                    <div className="action">
                        <PiHeart />
                    </div>
                </div>
            </div>
        </WrapperHouseAdvertisementCard>
    );
}

export default HouseAdvertisementCard;
