import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';

const PrivateRoute2 = ({ children }) => {
  const { currentUser } = useContext(AuthContext);

  // Verifica se o usuário está autenticado
  return currentUser ? children : <Navigate to="/entrar" />;
};

export default PrivateRoute2;