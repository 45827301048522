import styled from "styled-components"
import theme from "../../theme"

const WrapperCreateAccount = styled.div`
    display: flex;
    position: relative;

    .ilustration {
        width: 74.735%;
        position: relative;
        overflow-x: hidden;
        height: 100vh;

        img {
            object-fit: cover;
            position: absolute; 
            width: 100%;
            height: 100%; 
            top: 0;
            left: 0;
        }
    }  

    .form{
        width: 22%;
        margin: 0 auto;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
            width: 50%;
            margin-top: 40px;
        }

        .about {
            margin-top: 10px;
            color: #111;
            text-align: center;
            margin-bottom: 20px;
            font-size: .85rem;

            a {
                color: ${theme.fontColor.green};
                text-decoration: none;
                font-weight: 600;
            }
        }


        .login-button {
            padding: 8px 0;
            width: 100%;
            border-radius: 100px;
            color: #fffffe;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-bottom: 15px;
            font-size: .835rem;

            svg {
                font-size: 25px;
                margin-right: 7px;
            }

            span {
                font-weight: 600;
                margin-top: 2px;
            }

            &.facebook {
                background-color: #17559e;
            }
            &.google {
                background-color: #0086f9;
            }
        }

        .separator{
            border-top: 1px solid #111;
            margin: 30px 0px;
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            font-size: .9rem;

            span {
                position: absolute;
                padding: 0 15px;
                background-color: white;
                transform: translateY(-11px);
                color: #111;
            }
        }

        .form-item{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 2px;
            margin-bottom: 25px;
            width: 100%;

            span {
                color: ${theme.fontColor.green};
                font-weight: 600;
                user-select: none;
                font-size: .83rem;
            }

            input {
                border: 1px solid black;
                outline: none;
                width: calc(100% - 30px);
                padding: 8px 15px;
                border: 1px solid ${theme.backgroundColor.black9};
                border-radius: 5px;
                font-size: 1rem;
            }

            .inputbox {
                border: 1px solid black;
                outline: none;
                width: calc(100% - 30px);
                padding: 8px 15px;
                border: 1px solid ${theme.backgroundColor.black9};
                border-radius: 5px;
                font-size: 1rem;
                display: flex;
                align-items: center;
                justify-content: space-between  ;

                input {
                    border: none;
                    border-radius: 0;
                    padding: 0;
                    width: 85%;
                }

                svg {
                    cursor: pointer;
                }
            }

            .option {
                display: flex;
                align-items: center;
                gap: 5px;
                cursor: pointer;

                .circle {
                    padding: 10px;
                    border-radius: 100px;
                    border: ${theme.fontColor.green} 1px solid;
                    transition-property: background;
                    transition-duration: .2s;

                    &.active {
                        background-color: ${theme.fontColor.green};
                    }
                }

                .title{
                    font-size: 1rem;
                }
            }
        }

        .button{
            cursor: pointer;
            width: 100%;
            text-align: center;
            padding: 10px 0px;
            margin-bottom: 15px;
            font-weight: 600;
            color: white;
            background-color: ${theme.backgroundColor.green};
            border-radius: 100px;
            color: white;

            &.deactive {
                background-color: gray !important;
            }
        }

        .have-account {
            color: #111;
            font-size: .9rem;
            

            strong {
                cursor: pointer;
                color: ${theme.fontColor.green};
            }
        }
    }

    .feedback {
        color: #e60000 !important;
        font-size: .7rem !important;
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {

        .ilustration {
            width: 100%;
            position: absolute;
        }

        .form {
            width: 75%;
            padding: 10px 5%;
            margin: 0 auto;
            border-radius: 10px;
            z-index: 3;
            background-color: rgba(255,255,255, .90);
            margin-top: 30px;

            .logo {
                margin-top: 0px;
            }

            .separator {
                border-top: 1px solid rgba(191, 191, 191, .40);

                span {
                    background-color: rgba(235, 235, 235, .50);

                }
            }
        }
    }
    
    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        
        .ilustration {
            width: 100%;
            position: absolute;
            z-index: 1;
        }

        .form {
            width: 75%;
            padding: 20px 5%;
            border-radius: 10px;
            z-index: 3;
            background-color: rgba(255,255,255, .90);
            margin-top: 50px;

            .logo {
                margin-top: 0px;
            }

            .separator {
                border-top: 1px solid rgba(191, 191, 191, .40);

                span {
                    background-color: rgba(235, 235, 235, .50);
                }
            }
        }
    }
     
    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        .ilustration{
            width: 72%;
        }

        .form {
            width: 23%;

            .reset-password {
                font-size: .85rem;
            }

            .form-item {

                span {
                    font-size: .8rem;
                }

                input {
                    font-size: .96rem;
                }
            }

            .button {
                font-size: .95rem;
            }

            .have-account {
                font-size: .8rem;
            }

            .separator {
                font-size: .9rem;
                
                span {
                    transform: translateY(-11px);
                }
            }

            .login-button {
                font-size: .8rem;
            }
        }
    }
    
    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        
        .ilustration{
            width: 73.5%;
        }

        .form {
            width: 22%;

            .reset-password {
                font-size: .85rem;
            }

            .form-item {

                span {
                    font-size: .8rem;
                }

                input {
                    font-size: .96rem;
                }
            }

            .button {
                font-size: .95rem;
            }

            .have-account {
                font-size: .8rem;
            }

            .separator {
                font-size: .9rem;
                
                span {
                    transform: translateY(-11px);
                }
            }

            .login-button {
                font-size: .8rem;
            }
        }
    }
    
`

export default WrapperCreateAccount