import WrapperInfos from "./Styles-Infos";
import ProtectionIcon from "../../imgs/protection_icon.png"
import HouseIcon from "../../imgs/house_icon.png"
import MoneyIcon from "../../imgs/money_icon.png"

const Infos = () => {
    
    const cards = [
        {icon: ProtectionIcon, title: "Segurança:", description: "Faça anúncios com tranquilidade e proteção dos seus dados."},
        {icon: HouseIcon, title: "Imóveis do seu jeito:", description: "Encontrar um imóvel do seu jeito para alugar agora está mais fácil."},
        {icon: MoneyIcon, title: "Para renda ou para morar:", description: "Aqui você anuncia ou encontra o imóvel específico para a sua necessidade."},
    ]
  
    function Card(props) {
        return <div className="card">
            <img src={props.icon} alt={props.title} />
            <span className="title">{props.title}</span>
            <span className="description">{props.description}</span>
        </div>
    }

    return <WrapperInfos>
        {cards.map((card) => {
            return <Card
                icon={card.icon}
                title={card.title}
                description={card.description}
            />
        })}
    </WrapperInfos>
}

export default Infos