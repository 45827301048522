import WrapperMyData from "../../views/my-data/MyData.styles"
import NavBar from "../nav-bar/NavBar"

const MyInstitucional = () => {

    return <WrapperMyData>
        <NavBar />
        <div className="container-d">
            <span className="title">Nossa Política e Institucional</span>
            <span className="body">
                <strong>Nossa Declaração de Privacidade</strong> 
                <br /><br />
                Esta Declaração de Privacidade poderá ser atualizada com regularidade e, sempre que
                procedemos às alterações, estas serão publicadas em nossos sites.
                <br /><br />
                Última atualização: 25/09/2024
                <br /><br />
                No que consiste esta declaração de privacidade e de cookies Nesta declaração explicamos
                como coletamos, usamos, compartilhamos e protegemos as suas Informações Pessoais,
                quando você utiliza os nossos produtos, serviços e os nossos sites.
                <br /><br />
                <strong>Definições</strong> <br />
                Para os fins desta Política de Privacidade, as seguintes definições serão adotadas:
                <br /><br />
                Anunciantes: são todas as pessoas que se cadastram nos nossos sites e serviço e que concluem
                o cadastro de anúncio de um imóvel.
                <br /><br />
                Política de Privacidade: são os termos contidos neste documento que tratam sobre a
                privacidade.
                <br /><br />
                Usuários: são todas as pessoas que acessam os nossos sites, independentemente de se
                cadastrarem e receberem uma identificação individual e exclusiva, ou não.
                <br /><br />
                Você: são os Usuários ou Anunciantes, dependendo do contexto em que esta definição é
                utilizada.
                <br /><br />
                Outras definições encontradas ao longo deste documento estarão devidamente identificadas.
                <br /><br />
                <strong>Quais Informações Pessoais nós coletamos</strong>
                <br /><br />
                As informações que coletamos sobre você e o modo como as coletamos podem variar de
                acordo com os produtos e serviços que você utiliza e contrata, a forma como os produtos e
                serviços foram por si usados e como interagiu com o nosso site, mesmo não sendo Cliente.
                Estas informações podem ainda ser obtidas através de um terceiro que tenha a sua permissão
                para compartilhar suas informações conosco. Algumas das informações que você poderá
                compartilhar conosco são seu nome completo, um nome de usuário e senha, endereço de e-
                mail, número de telefone, endereço físico ou, para determinados produtos e serviços, o seu
                número de identidade, seu número de CPF, seu número de CNPJ, e para determinadas
                transações comerciais eletrônicas, informações do seu cartão de crédito.
                <br /><br />
                <strong>Como trataremos as suas Informações Pessoais</strong>
                <br /><br />
                Para a celebração e execução do seu contrato e para dar seguimento aos seus pedidos. No
                interesse legítimo do negócio das nossas marcas, por exemplo, para prevenção de fraudes,
                para garantia da segurança da nossa rede e serviços, para marketing direto e para melhoria
                dos nossos serviços. Sempre que dependemos deste fundamento jurídico para tratar os seus
                dados, avaliamos os nossos interesses comerciais para garantir que não se sobrepõem aos
                seus direitos.
                <br /><br />
                Cumprimento de uma obrigação legal a que os nossos sites estejam ligados, que inclui, por
                exemplo, o cumprimento de obrigações contabilísticas e fiscais, as quais estão sujeitas a
                rigorosas políticas internas (tais como períodos de conservação).
                <br /><br />
                No seu consentimento, nos casos em que as nossas marcas não recorram a outro fundamento
                jurídico. Este consentimento pode ser retirado a qualquer momento.
                <br /><br />
                <strong>Como coletamos suas Informações Pessoais</strong>
                <br /><br />
                As nossas marcas coletam informações pessoais fornecidas por você, informações estas
                capazes de identificá-lo. Estas informações coletadas podem variar de acordo com o seu uso
                do site, bem como com o tipo de informação que você opta por nos fornece. É possível visitar
                a maioria das seções dos serviços sem a necessidade de que você envie nenhuma informação
                pessoal identificável. Entretanto, para acesso a determinados conteúdos e recursos oferecidos,
                poderemos capturar telas de acesso e solicitar de você algumas informações pessoais
                identificáveis. Informação pessoal identificável significa qualquer informação individual que
                permita a sua identificação e que você nos fornece online, incluindo, mas não se limitando a
                seu nome completo, um nome de usuário e senha, endereço de e-mail, número de telefone,
                endereço físico ou, para determinados produtos e serviços, o seu número de identidade, seu
                número de CPF, seu número de CNPJ, e para determinadas transações comerciais eletrônicas,
                informações do seu cartão de crédito. Você será sempre informado sobre os dados que estão
                sendo coletados, sendo seu exclusivo critério fornecê-los ou não, estando ciente de que, se
                optar por não fornecê-los poderá não ter o acesso ao conteúdo desejado, por questões de
                segurança e, na maioria dos casos, pela impossibilidade de prestarmos o serviço solicitado sem
                as requeridas informações.
                <br /><br />
                Rastreamos determinadas informações sobre você, à medida em que você visita e utiliza
                nossos serviços e recursos, com o único propósito de entender melhor como os nossos
                serviços são utilizados e como nossos serviços podem ser aprimorados para melhor atendê-lo.
                Estas informações podem incluir endereço de protocolo de Internet (“IP”) de seu computador,
                as URLs e telas que você visitou, o número de vezes que você visitou cada página dos nossos
                sites, quais downloads e/ou pesquisas você fez, quanto tempo você passou em determinadas
                seções dos nossos sites, bem como seu tipo de navegador. Estas informações são colhidas
                automaticamente através de “cookies”, os quais são descritos detalhadamente em uma seção
                específica sobre eles. Estas informações coletadas automaticamente também são regidas por
                nossa Política de Privacidade e sistemas de segurança, não sendo utilizadas para nenhum
                outro propósito que não os descritos neste item. Embora tomemos todas as precauções com
                relação à segurança das suas Informações Pessoais e privacidade, nós não nos
                responsabilizamos por quaisquer prejuízos advindos da divulgação de tais informações por
                motivos legais ou de ordem judicial.
                <br /><br />
                <strong>Outras informações que coletamos</strong>
                <br /><br />
                Localização - os seus dados de localização podem ser coletados quando você nos autoriza a
                realizar tal coleta e ativa os serviços ou funcionalidades de localização que utilizam os dados
                do Sistema de Posicionamento Global (GPS).
                <br />
                Também adicionamos às informações da sua conta, informações tais como datas de
                pagamento, valores em dívida ou pagamentos recebidos, assinaturas que efetue, ou outras
                informações relacionadas com a sua conta.
                <br />
                Informação de autenticação – poderemos recolher senhas, respostas a perguntas de
                recuperação de senha e outras informações de segurança usadas para a autenticação e acesso
                a contas e serviços.
                <br /><br />
                <strong>Sobre Cookies</strong>
                <br /><br />
                “Cookies” são pequenos arquivos eletrônicos que são armazenados no seu navegador de
                internet para que nós possamos reconhecê-lo na próxima vez que você visitar algum dos
                nossos serviços. Você está livre para recusar quaisquer cookies que nós utilizamos, se seu
                navegador assim permitir. Alguns de nossos Anunciantes e provedores de serviços também
                podem utilizar-se dos seus próprios cookies. Além disso, nós e nossos provedores de serviços
                às vezes utilizamos “clear GIFs” ligados com cookies e outras funções de sites. Clear GIFs
                realizam funções administrativas sem atrapalhar a sua experiência online. Eles podem ser
                usados para realizar tarefas estatísticas e administrativas incluindo medição do site e tráfego
                de páginas, verificando publicidade e posicionamento de imagens na web. Clear GIFs ou os
                nossos cookies não coletam dados pessoais. Apesar de não coletarem informações pessoais,
                eles podem ser utilizados para auxiliar na sua identificação.
                <br /><br />
                <strong>Como utilizamos as suas informações pessoais</strong>
                <br /><br />
                As Informações pessoais que você nos fornece quando você se torna um Usuário ou
                Anunciante registrado nos nossos sites são usadas primeiramente para nos ajudar a
                personalizar sua experiência online, aumentar a conveniência do seu acesso a novas
                ferramentas, produtos e serviços, e, se for o caso, fornecer produtos e/ou serviços que você
                compra ou solicita. Estas informações pessoais também podem ser compartilhadas entre os
                sites relacionados, para tornar o uso dos mesmos mais conveniente ao, por exemplo, limitar o
                número de vezes que você tem que se registrar conosco ao inserir nome de usuário e senhas
                cadastrados. O seu endereço de e-mail serve como um identificador único do nosso sistema de
                registro, e juntamente com sua senha, foi designado para prevenir o acesso não autorizado a
                quaisquer informações que você escolheu armazenar em nossos sites. Os dados que você nos
                fornece sobre suas preferências pessoais e demográficas (ex: sua idade, endereço, profissão
                ou similares) podem ser usados para nos auxiliar a oferecer conteúdo, propagandas, produtos
                e serviços que nós acreditamos ser interessantes a você. Além disso, nós podemos
                compartilhar estas informações, contemplando seus dados pessoais, com nossos Parceiros e
                Anunciantes para fins de execução dos contratos e negócios, e, também para que os Parceiros
                e Anunciantes possam apresentar seus anúncios, produtos e serviços de forma mais efetiva.
                Esses informações, serão compartilhadas de forma anônima, sempre que possível ou exigido
                por lei.
                <br /><br />
                Se você esquecer a sua senha para qualquer área dos nossos sites ou dos sites relacionados,
                nós oferecemos sistema de recuperação de senha automatizado, o qual pode ser acessado ao
                clicar no link de “Esqueci minha senha?” na nossa página de registro.
                Suas informações pessoais, em especial seu e-mail, poderão ser utilizadas para que nós, bem
                como nossos Parceiros e Anunciantes, enviemos materiais e informações que consideremos
                ser do seu interesse, tais como, mas não se limitando a, produtos e promoções dos nossos
                serviços e aos sites relacionados. Adicionalmente, nossos Parceiros e Anunciantes com os
                quais você optou por compartilhar suas informações (através do interesse em algum anúncio
                veiculado em nossos sites) poderão utilizar seus dados pessoais para outras finalidades não
                especificadas nesta Política, desde que este tratamento de dados se enquadre em uma das
                hipóteses do artigo 7º da Lei 13.709/18 (LGPD).
                <br /><br />
                Como partilhamos as suas informações pessoais <br />
                Quando for aplicável, compartilharemos as suas informações com: <br />
                <strong>Como gerenciamos fraudes e garantimos o cumprimento da lei</strong>
                <br /><br />
                Divulgaremos informações às autoridades competentes se tal for necessário, para garantir
                proteção contra fraudes, defender os nossos direitos ou bens ou para proteger os interesses
                dos nossos clientes. Também podemos ter de divulgar as suas informações no cumprimento
                da nossa obrigação legal de resposta aos pedidos das autoridades competentes. Os seus dados
                pessoais só serão disponibilizados quando acreditarmos, de boa fé, que somos obrigados a
                fazê-lo segundo a lei, e após uma avaliação exaustiva de todos os requisitos legais.
                <br /><br />
                <strong>Por quanto tempo armazenamos as suas Informações Pessoais</strong>
                <br /><br />
                Armazenaremos as suas informações durante o período exigido pela lei. Se não existir um
                requisito legal, só as armazenaremos enquanto forem necessárias ao cumprimento de cada
                finalidade de tratamento. No caso dos dados de acesso e de geolocalização, os mesmos
                poderão ser conservados até o final do período de seu contrato ou, em caso de um usuário
                registrado em nossos sites, enquanto tal registro se mantiver ativo. Os dados de cobrança são
                conservados durante a execução do contrato e, após a sua cessação, poderão ainda, ser
                conservados durante um período razoável, caso decida voltar a usar os nossos serviços. Nós,
                ou um dos nossos parceiros, poderemos contactá-lo nesta altura para o oferecimento de
                novos produtos e serviços, caso você não tenha optado por não receber as nossas
                comunicações de marketing. Os dados de cobrança também serão conservados após o termo
                do contrato, durante o período necessário para o cumprimento das nossas obrigações legais.
                <br /><br />
                <strong>Como fazemos a segurança das suas Informações Pessoais</strong>
                <br /><br />
                Sempre que Informações Pessoais são armazenadas em nossos sistemas, estas informações
                são protegidas contra o acesso ou uso não autorizado a terceiros. Além disso, estas
                informações são mantidas em servidores protegidos contra tais acessos não autorizados. Visto
                que suas informações financeiras e Informações Pessoais (ex: seu número de identidade) são
                especialmente sensíveis, nós tomamos todas as precauções e medidas para mantê-las seguras.
                Toda a transferência de informações entre você e os nossos sites é feita através de conexões
                seguras de Internet, utilizando sistemas criptográficos SSL (“Secure Sockets Layer”) para
                informações de natureza financeira, principal tecnologia atualmente disponível para segurança
                online e chaves de segurança eletrônicas. Nos termos desta Política de Privacidade, caso suas
                informações venham a ser armazenadas por terceiros, não teremos qualquer responsabilidade
                sobre sua guarda e segurança, considerando que estes não terão qualquer forma de controle
                ou ingerência sobre os mesmos.
                <br /><br />
                <strong>Seus direitos</strong>
                <br /><br />
                Você tem o direito de:
                <br /><br />
                Confirmação de que existe um ou mais tratamento de dados sendo realizado; <br />
                Acesso aos dados pessoais armazenados pelos nossos sites que lhe dizem respeito;<br />
                Correção dos seus dados pessoais que estejam incompletos, inexatos ou desatualizados;<br />
                Ser informado sobre o compartilhamento de seus dados com entes públicos e privados, caso
                sejam solicitados ou autorizados;<br />
                Informação sobre não consentimento, ou seja, sobre a opção de não autorizar o tratamento e
                as consequências da não autorização;<br />
                Revogação do consentimento nos termos da lei;<br />
                Reclamar contra as nossas marcas em casos de uso não autorizado ou inadequado dos seus
                dados junto à autoridade nacional;
                <br /><br />
                Oposição, caso discorde de um tratamento realizado sem o seu consentimento e o considere
                irregular;<br />
                Eliminação dos seus dados, exceto quando o tratamento é legal (previsto na legislação
                brasileira), mesmo sem o seu consentimento.<br /><br />
                Para solicitações ou dúvidas sobre estes direitos, envie um e-mail para
                privacidade@compreimoveis.com
            </span>
        </div> 
    </WrapperMyData>
}

export default MyInstitucional