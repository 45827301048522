import styled from "styled-components";
import theme from "../../theme";

const WrapperHouseAdvertisementSession = styled.div`
    margin: 0 18%;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        font-size: 1.5rem !important;
        font-family: ${theme.fontFamily.medium};
        letter-spacing: 1px;
        margin-bottom: 30px;
        font-weight: bold;
    }

    .slideShowContainer {
        display: flex;
        padding: 20px 0; 
        width: 100%;
        overflow: hidden;

        .slideShowWrapper {
            display: flex;
            transition: transform 0.5s ease;

            
        }
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        margin: 0 3%;
        margin-top: 60px;

        .title {
            font-size: 30px;
        }
    }

    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}){
        margin: 0 15%;
        

        .title {
            font-size: 40px;
        }
    }

    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}){
        margin: 0 10%;
        margin-top: 60px;

        .title {
            font-size: 1.2rem !important;
            margin-bottom: 20px;
        }
    }
`

export default WrapperHouseAdvertisementSession;
