import React, { useState } from 'react';
import styled from 'styled-components';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import theme from '../../theme';
import { Link, useNavigate } from 'react-router-dom';

function getWidthByVisibleItems(visibleItems) {
  let Width = "";
  let FactorToMultiple = 0;

  if (visibleItems === 4) {
    Width = "width: calc(25% - 20px);";
    FactorToMultiple = 100;
  }

  if (visibleItems === 2) {
    Width = "width: calc(50% - 20px);";
    FactorToMultiple = 200;
  }

  if (visibleItems === 1) {
    Width = "width: calc(100% - 20px);";
    FactorToMultiple = 100;
  }

  return [Width, FactorToMultiple];
}

const CarouselContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;

const CarouselWrapper = styled.div`
  width: 90%;
  display: flex;
  transition: transform 0.5s ease;
  transform: translateX(-${props => ((props.currentIndex * props.factorToMultiple) / props.visibleItems)}%);

  svg {
    width: 10%;
    background-color: white;
  }
`;

const Card = styled.div`
  flex: 0 0 auto;
  ${props => props.width}
  margin: 0 10px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
  }

`;

const Controller = styled.div`
  width: 5%;
  z-index: 9;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    font-size: 2rem;
  }
`



const CarouselTypeB = ({ items, visibleItems }) => {
  const navigate = useNavigate()
  const returnMaxScroll = () => {
    if (visibleItems === 4) {
      return items.length - 4;
    }

    if (visibleItems === 2) {
      return items.length - 4;
    }

    return items.length - 1;
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [Width, FactorToMultiple] = getWidthByVisibleItems(visibleItems);
  const maxOfSlideshow = returnMaxScroll();

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex >= maxOfSlideshow ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex <= 0 ? maxOfSlideshow : prevIndex - 1
    );
  };

  const handleNavigate = (url, type) => {
    navigate(`${url}`, {state: {data: type}})
  } 

  return (
    <CarouselContainer>
      <Controller>
        <FaChevronLeft className='controleree' onClick={handlePrev} />
      </Controller>
      <CarouselWrapper
        currentIndex={currentIndex}
        visibleItems={visibleItems}
        factorToMultiple={FactorToMultiple}
      >
        {items.map((item, index) => (
          <Card key={index} onClick={() => handleNavigate(item.link, item.type)} visibleItems={visibleItems} width={Width}>
            <Link to={item.link}>
              <img src={item.img} alt="" />
            </Link>
          </Card>
        ))}
      </CarouselWrapper>
      <Controller>
        <FaChevronRight className='controleree' onClick={handleNext} />
      </Controller>
    </CarouselContainer>
  );
};

export default CarouselTypeB;
