import { useEffect, useState } from "react";
import { WrapperBoleto } from "../payment/Payment.styles";
import axios from "axios";
import Barcode from "react-barcode";

function PaymentBoleto(props) {
    const [step, setStep] = useState(1);
    const [barcode, setBarcode] = useState(props.barcode || false); // initialize with prop
    const [pdfBarcode, setPdfBarcode] = useState(props.pdfBarcode || false); // initialize with prop
    const [allFilled, setAllFilled] = useState(false);
    const [cep, setCEP] = useState("");
    const [city, setCity] = useState("");
    const [street, setStreet] = useState("");
    const [neightboard, setNeightboard] = useState("");
    const [number, setNumber] = useState("");
    const [state, setState] = useState("");
    const [complement, setComplement] = useState("");

    // When the props change (barcode, pdfBarcode), update the state
    useEffect(() => {
        setBarcode(props.barcode);
        setPdfBarcode(props.pdfBarcode);
    }, [props.barcode, props.pdfBarcode]);

    const handleSubmit = () => {
        const addressData = {
            cep,
            city,
            street,
            neightboard,
            number,
            state,
            complement
        };

        // Send address data to parent component
        props.onAddressData(addressData);
        setStep(2); // move to step 2 (display boleto details)
    };

    const handleCepBlur = (cep) => {
        setCEP(cep);
        axios
            .get(`https://viacep.com.br/ws/${cep}/json/`)
            .then((result) => {
                const data = result.data;
                if (data.erro) return;
                setCity(data.localidade);
                setStreet(data.logradouro);
                setNeightboard(data.bairro);
                setState(data.uf);
            })
            .catch((error) => {
                console.log("Erro ao buscar CEP:", error);
            });
    };

    function copyBarcode() {
        navigator.clipboard.writeText(barcode)
        .then(() => {
        })
        .catch((err) => {
            console.error("Falha ao copiar código de barras: ", err);
        });
    }

    // Check if all address fields are filled
    useEffect(() => {
        const addressData = {
            cep,
            city,
            street,
            neightboard,
            number,
            state,
        };
        const allFieldsFilled = Object.values(addressData).every((value) => value.trim() !== "");
        setAllFilled(allFieldsFilled);
    }, [cep, city, street, neightboard, number, state, complement]);

    return (
        <WrapperBoleto>
            {(step === 2 || barcode) && (
                <>
                    <span className="title-session">
                        Seu pedido será processado após o pagamento do Boleto
                    </span>
                    <div className="barcode">
                        {/* {barcode && <img src={barcode} alt="Barcode" />} */}
                        {barcode && <Barcode 
                                        value={barcode} 
                                        />}
                    </div>
                    {pdfBarcode && (
                        <a className="button" target="_blank" href={pdfBarcode} rel="noopener noreferrer">
                            Visualizar Boleto
                        </a>
                    )}
                    <span className="button" onClick={copyBarcode}>Copiar Código do Boleto</span>
                    <span className="title">Informações importantes sobre o pagamento do Boleto</span>
                    <ul>
                        <li>Você pode não conseguir o pagamento imediatamente. Neste caso, tente novamente após 30 segundos.</li>
                        <li>Se o Boleto não for pago até a data de vencimento, seu pedido será cancelado.</li>
                        <li>Alguns lugares recebem o pagamento, mas só o repassam para nós no dia útil seguinte, quando o boleto já ter vencido. <br /> Atente-se às regras de onde você fará o pagamento para evitar que seu pedido seja cancelado.</li>
                    </ul>
                </>
            )}
            {(step === 1 && !barcode) && (
                <div className="adrress">
                    <span className="title">Endereço de Cobrança</span>
                    <input
                        type="text"
                        placeholder="CEP"
                        value={cep}
                        onBlur={(e) => handleCepBlur(e.currentTarget.value)}
                        onChange={(e) => setCEP(e.currentTarget.value)}
                    />
                    <input type="text" placeholder="Estado" value={state} disabled />
                    <input type="text" placeholder="Cidade" value={city} disabled />
                    <input type="text" placeholder="Rua/Avenida" value={street} disabled />
                    <input type="text" placeholder="Bairro" value={neightboard} disabled />
                    <input type="text" placeholder="Número" value={number} onChange={(e) => setNumber(e.currentTarget.value)} />
                    <input type="text" placeholder="Complemento" value={complement} onChange={(e) => setComplement(e.currentTarget.value)} />
                    <div className="button-container">
                        {allFilled ? (
                            <span className="button" onClick={handleSubmit}>
                                Próximo
                            </span>
                        ) : (
                            <span className="button deactive">Próximo</span>
                        )}
                    </div>
                </div>
            )}
        </WrapperBoleto>
    );
}

export default PaymentBoleto;
