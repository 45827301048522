import { useParams } from "react-router-dom";
import WrapperBlog from "./Blog.styles";
import React, { useEffect, useState } from "react";
import SuggestionCardB from "../../components/suggestion_card_b/SuggestionCardB";
import BannerLogo from "../../imgs/banners/a.png"
import { getAllBlogPosts } from "../../hooks/firebase";

const Blog = () => {
    const { slug } = useParams()
    const [data, setData] = useState(null)
    const [anotherBlogs, setAnotherBlogs] = useState(null)
 
    useEffect(() => {
        getAllBlogPosts().then((result) => {
            var another = []
            result.forEach(item => {
                if (item.slug === slug) {
                    setData(item)
                    console.log(item)
                } else {
                    another.push(item)
                }
            })
            setAnotherBlogs(another)
        })
        
    },[slug])

    return <WrapperBlog>
        {data !== null &&
            <>
                <div className="post-metadata">
                    <div className="location">
                        <span>Home</span>
                        <span>{">>"}</span>
                        <span>{data.theme}</span>
                        <span>{">>"}</span>
                        <span>{data.title}</span>
                    </div>
                    <div className="details">
                        <span className="date">{data.upload}</span>
                        <span className="theme">{data.theme}</span>
                        <span className="author">Autor: {data.author}</span>
                    </div>
                    <span className="title">{data.title}</span>
                    <div className="share">
                        <span>Compartilhe: </span>
                    </div>
                </div>
                <div className="body">
                    <div className="content">
                        <img src={data.bannerDesktop} alt={data.title} />
                        <span>
                        {data.body.split("\\n\\n").map((paragraph, index) => (
                            <React.Fragment key={index}>
                                {paragraph.split("\\n").map((line, lineIndex) => (
                                    <React.Fragment key={lineIndex}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                                <br />
                            </React.Fragment>
                        ))}
                        </span>
                    </div>
                    <div className="another-blogs">
                        <span className="title">
                            Leia mais...
                        </span>
                        {anotherBlogs.map(blog => {
                            return <SuggestionCardB 
                                data={{img: blog.bannerMobile, link: `/blog/${blog.slug}`, title: blog.title, date: blog.upload, theme: blog.theme}}
                            />
                        })}
                            {/* <SuggestionCardB data={{img: "", link: "", title: "Titulo", date: "12/12/2024", theme: "Comprar"}} />
                            <SuggestionCardB data={{img: "", link: "", title: "Titulo", date: "12/12/2024", theme: "Comprar"}} />
                            <SuggestionCardB data={{img: "", link: "", title: "Titulo", date: "12/12/2024", theme: "Comprar"}} />
                            <SuggestionCardB data={{img: "", link: "", title: "Titulo", date: "12/12/2024", theme: "Comprar"}} /> */}
                    </div>
                </div>
            </>
        }
    </WrapperBlog>
}

export default Blog