export const getModel = async () => {
    const nsfwjs = window.nsfwjs;
    if (nsfwjs) {
        try {
            const loadedModel = await nsfwjs.load("/models/");
            return loadedModel;
        } catch (error) {
            console.error("Erro ao carregar o modelo:", error);
        }
    } else {
        console.error("nsfwjs não foi encontrado!");
    }
}