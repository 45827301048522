import styled from "styled-components";
import theme from "../../theme";

const WrapperSearch = styled.div`
    margin: 10px 15%;
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .property-filters {
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .property-filters-top {
            display: flex;
            gap: 22px;

            input {
                width: 28.65%;
                padding: 7px 20px;
                font-size: 1rem;
                outline: none;
                border: 1px solid black;
                border-radius: 5px;
            }

            .property-filter {
                padding: 8px 20px;
                font-size: 1.05rem;
                border: 1px solid black;
                border-radius: 5px;
                font-weight: 600;
                cursor: pointer;
                
                &.active{
                    background-color: ${theme.fontColor.green};
                    border-color: ${theme.fontColor.green};
                    color: white;
                }
            }
        }

        .property-filters-bottom {
            display: flex;
            gap: 30px;
            align-items: end;

            .property-filter {
                display: flex;
                flex-wrap: wrap;
                width: calc(100% / 7);
                gap: 4px;
                position: relative;

                &:nth-child(1),
                &:nth-child(2){
                    width: calc(100% / 9.5);

                    .title {
                        width: 100%;
                    }
                }

                .filter-selectbox-top {
                    width: calc(80% - 20px);
                    gap: 7px;
                    border: 1px solid black;
                    border-radius: 5px;
                    padding: 1px 10px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .square {
                        padding: 6px;
                        border-radius: 3px;
                        background-color: ${theme.fontColor.green};

                        &::before{
                            content: "";
                        }
                    }

                    .title {
                        font-weight: normal;
                        font-size: 1rem;
                    }

                    svg {
                        font-size: 1.2rem;
                    }
                }

                .filter-selectbox {
                    z-index: 3;
                    position: absolute;
                    background-color: white;
                    border: 1px solid black;
                    border-radius: 5px;
                    width: 150%;
                    padding: 5px 13px;
                    transform: translateY(60px);
                    user-select: none;
                    visibility: hidden;
                    height: 250px;
                    overflow: auto;

                    &.pac-item.pac-item-selected .pac-logo {
                        display: none !important;
                    }

                    &::-webkit-scrollbar {
                        width: 10px; 
                    }

                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #888; 
                        border-radius: 5px; 
                    }

                    .selectbox-option {
                        display: flex;
                        align-items: center;
                        gap: 7px;
                        cursor: pointer;    

                        .square{
                            padding: 6px;
                            border-radius: 3px;
                            border: 1px solid black;

                            &::before{
                                content: "";
                            }

                            &.active{
                                background-color: ${theme.fontColor.green};
                                border: 1px solid ${theme.fontColor.green};
                            }
                        }
                    }

                    &.show {
                        visibility: visible;
                    }
                }

                .inputs {
                    display: flex;
                    gap: 10px;
                    
                    .input-container {

                        .input-box {
                            border-radius: 5px;
                            padding: 1px 5px;
                            border: solid 1px black;
                            display: flex;
                            gap: 2px;
                            font-weight: 600;

                            .prefix {
                                font-size: .9rem;
                            }

                            input {
                                width: 80%;
                                border: none;
                                outline: none;
                                font-weight: 600;
                            }
                        }
                    }
                }

                .title {
                    width: 60%;
                    font-size: .8rem;
                    font-weight: 600;
                }

                .property-opts {
                    width: 100%;
                    display: flex;
                    gap: 10px;
                    justify-content: space-between;

                    .button {
                        width: 19%;
                        border: 1px solid black;
                        border-radius: 5px;
                        padding: 4px;
                        font-size: .8rem;
                        font-weight: 600;
                        text-align: center;
                        cursor: pointer;
                        user-select: none;

                        &.active{
                            background-color: ${theme.fontColor.green};
                            border-color: ${theme.fontColor.green};
                            color: white;
                        }
                    }
                }

            }
        }
    }

    .properties {
        width: 49.5%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        overflow-y: auto;
        height: 70vh;

        &::-webkit-scrollbar {
            width: 10px;
            
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            
        }

        &::-webkit-scrollbar-thumb {
            background: #888; 
            border-radius: 5px;
        }
    }

    .map {
        width: 49.5%;

        iframe {
            height: 100%;
            width: 100%;
            border: 0;
        }
    }

    .filters-mobile-menu, .layer-filters {
        display: none;
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        margin: 10px 7%;
        margin-top: 40px;
        margin-bottom: 70px;
        
        .layer-filters {
            position: fixed;
            height: 100vh;
            right: 0;
            width: 15%;
            margin-top: -10%;
            background-color: rgba(0,0,0,.4);
            display: none;

            &.show {
                display: block;
            }
        }

        .filters-mobile-menu {
            display: none;
            position: fixed;
            height: 100vh;
            margin-left: -7%;
            margin-top: -10%;
            width: calc(85% - 20px);
            padding: 0 10px;
            padding-top: 10px;
            background-color: white;
            z-index: 9;

            &.show {
                display: block;
            }
            

            .top {
                margin-top: 15px;
                display: flex;
                justify-content: space-between;
                font-size: 1.4rem;
                
            }

            .filters {
                display: flex;
                flex-direction: column;
                gap: 30px;
                margin-top: 20px;

                .property-filter {
                    display: flex;
                    flex-wrap: wrap;
                    width: calc(100%);
                    gap: 4px;
                    position: relative;

                    &:nth-child(1),
                    &:nth-child(2){
                        width: calc(100%);

                        .title {
                            width: 100%;
                            font-size: 1rem;
                        }
                    }

                    

                    .filter-selectbox-top {
                        width: calc(100% - 20px);
                        gap: 7px;
                        border: 1px solid black;
                        border-radius: 5px;
                        padding: 1px 10px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        .square {
                            padding: 6px;
                            border-radius: 3px;
                            background-color: ${theme.fontColor.green};

                            &::before{
                                content: "";
                            }
                        }

                        .title {
                            font-weight: normal;
                            font-size: 1rem;
                        }

                        svg {
                            font-size: 1.2rem;
                        }
                    }

                    .filter-selectbox {
                        z-index: 3;
                        position: absolute;
                        background-color: white;
                        border: 1px solid black;
                        border-radius: 5px;
                        width: calc(97% - 20px);
                        padding: 5px 13px;
                        transform: translateY(60px);
                        user-select: none;
                        visibility: hidden;

                        .selectbox-option {
                            display: flex;
                            align-items: center;
                            gap: 7px;
                            cursor: pointer;    

                            .square{
                                padding: 6px;
                                border-radius: 3px;
                                border: 1px solid black;

                                &::before{
                                    content: "";
                                }

                                &.active{
                                    background-color: ${theme.fontColor.green};
                                    border: 1px solid ${theme.fontColor.green};
                                }
                            }
                        }

                        &.show {
                            visibility: visible;
                        }
                    }

                    .inputs {
                        display: flex;
                        gap: 10px;
                        
                        .input-container {

                            .input-box {
                                border-radius: 5px;
                                padding: 1px 5px;
                                border: solid 1px black;
                                display: flex;
                                gap: 2px;
                                font-weight: 600;


                                input {
                                    width: 80%;
                                    border: none;
                                    outline: none;
                                    font-weight: 600;
                                }
                            }
                        }
                    }

                    .title {
                        width: 60%;
                        font-size: 1rem;
                        font-weight: 600;
                    }

                    .property-opts {
                        width: 100%;
                        display: flex;
                        gap: 10px;
                        justify-content: space-between;

                        .button {
                            width: 19%;
                            border: 1px solid black;
                            border-radius: 5px;
                            padding: 4px;
                            font-size: .8rem;
                            font-weight: 600;
                            text-align: center;
                            cursor: pointer;
                            user-select: none;

                            &.active{
                                background-color: ${theme.fontColor.green};
                                border-color: ${theme.fontColor.green};
                                color: white;
                            }
                        }
                    }
                }
            }
        }

        .property-filters {
            margin-bottom: 40px;
            
            .property-filters-top {
                flex-wrap: nowrap;
                display: flex;

                .filters-mobile {
                    border: 1px solid black;
                    display: flex;
                    align-items: center;
                    padding: 0px 10px;
                    border-radius: 7px;
                }

                input {
                    padding: 8px 16px;
                    width: calc(100% - 32px);
                    font-size: 1.1rem;
                }

                .property-filter{
                    padding: 4px 16px;
                    font-size: 1.1rem;
                    width: calc(49% - 44px);
                    text-align: center;
                }
            }

            .property-filters-bottom {
                display: none !important;
                &::-webkit-scrollbar {
                    height: 10px;           /* Define uma altura fixa para a barra de rolagem */
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #888; /* Cor da barra de rolagem */
                    border-radius: 6px;
                }

                flex-wrap: nowrap; /* Evita a quebra das linhas para permitir o scroll horizontal */
                margin-top: 20px;
                overflow-x: auto; /* Adiciona o scroll horizontal se o conteúdo ultrapassar a largura */
                display: flex; /* Garante que o conteúdo fique em linha para o scroll funcionar */
                gap: 30px; /* Ajuste de espaço entre os elementos */
                height: 100px;

                .property-filter {
                    flex: 0 0 auto; /* Garante que cada item não seja reduzido para caber no contêiner */
                    width: calc(49% - 15px);

                    &:first-child {
                        width: calc(49% - 20px);

                        .title {
                            width: 100%;
                        }
                    }

                    .title {
                        font-size: .9rem;
                    }

                    .filter-selectbox-top {
                        .title {
                            font-size: .9rem;
                        }
                    }

                    .filter-selectbox {
                        font-size: .9rem;
                    }

                    .property-opts {
                        gap: 2px;
                    }

                    .inputs {
                        .input-container {
                            .input-box {
                                align-items: center;

                                .prefix {
                                    font-size: .8rem;
                                }

                                input {
                                    padding: 4px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .properties {
            width: 100%;
        }

        .map {
            width: 100%;
            margin-top: 20px;
            height: 400px;
        }
    }
    
    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        margin: 10px 7%;
        margin-top: 40px;

        .property-filters {
            .property-filters-top {
                flex-wrap: wrap;

                input {
                    padding: 8px 16px;
                    width: calc(100% - 32px);
                    font-size: 1.1rem;
                }

                .property-filter{
                    padding: 4px 16px;
                    font-size: 1.1rem;
                    width: calc(24% - 44px);
                    text-align: center;
                }
            }

            .property-filters-bottom {
                flex-wrap: wrap;
                margin-top: 20px;

                .property-filter {
                    width: calc(24% - 15px);

                    &:first-child{
                        width: calc(24% - 20px);

                        .title {
                            width: 100%;
                        }
                    }

                    .title {
                        font-size: .9rem;
                    }

                    .filter-selectbox-top {
                        .title {
                            font-size: .9rem;
                        }
                    }
                    .filter-selectbox {
                        font-size: .9rem;
                    }

                    .property-opts {
                        gap: 2px;

                    }

                    .inputs {
                        .input-container {
                            .input-box {
                                align-items: center;
                                .prefix {
                                    font-size: .8rem;
                                }

                                input {
                                    padding: 4px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .properties {
            width: 100%;
        }

        .map {
            margin-top: 40px;
            width: 100%;
            height: 400px;
        }
    }
    
    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        margin: 10px 7%;
        margin-top: 40px;

        .property-filters {
            .property-filters-top {
                input {
                    padding: 2px 16px;
                    font-size: .95rem;
                }

                .property-filter{
                    padding: 4px 16px;
                    font-size: .95rem;
                }
            }
            .property-filters-bottom {

                .property-filter {
                    width: calc(100% / 7);

                    .title {
                        font-size: .7rem;
                    }
                    
                    .filter-selectbox {
                        font-size: .9rem;
                    }

                    .property-opts {
                        gap: 2px;

                        
                        .button {

                        }
                    }

                    .inputs {
                        .input-container {
                            .input-box {
                                .prefix {
                                    font-size: .8rem;
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
    
    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        .property-filters {
            .property-filters-top {
                input {
                    padding: 2px 16px;
                    font-size: .95rem;
                }

                .property-filter{
                    padding: 4px 16px;
                    font-size: .95rem;
                }
            }
            .property-filters-bottom {
                justify-content: space-between;

                .inputs {

                }

                .property-filter{

                }
            }
        }
    }
    
    @media (max-width: ${theme.breackpoint.bigScreens}) and (min-width: ${theme.breackpoint.largeScreens}) {
        
    }
    
`

export default WrapperSearch