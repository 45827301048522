import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Home from './views/home/Home';
import Header from './components/header/Header';
import Footer from './components/footer_b/Footer';
import Plans from './views/plans/Plans';
import CreateAccountPage from './views/create-account/CreateAccount';
import SearchPage from './views/search/Search';
import RegisterPropertyPage from './views/register-property/RegisterProperty';
import MyData from './views/my-data/MyData';
import MyPlan from './views/my-plan/MyPlan';
import PrivacyPolicy from './views/privacy-policy/PrivacyPolicy';
import TermsOfUse from './views/terms-of-use/TermsOfUse';
import SwitchPassword from './views/switch-password/SwitchPassword';
import MyProperties from './views/my-properties/MyProperties';
import ViewProperty from './views/view-property/ViewProperty';
import Payment from './views/payment/Payment';
import Loggin from './views/loggin/Loggin';
import Blog from './views/blog/Blog';
import { AuthContext, AuthProvider } from './context/AuthContext';
import EditPropertyPage from './views/edit-property/EditProperty';
import { useContext } from 'react';
import MyChats from './components/my-chats/MyChats';
import MyChangePassword from './components/my_change_password/MyChangePassword';
import MyInstitucional from './components/my_institutional/MyInstitutional';
import ContainerMyProperties from './components/my_properties/MyProperties';
import MyFavorites from './components/my_favorites/MyFavorites';
import ChatContainer from './components/chat_container/ChatContainer';
import BlogHome from './views/blog-home/BlogHome';
import { LoadScript } from '@react-google-maps/api';
import theme from './theme';
import HeaderMobile from './components/header_mobile/HeaderMobile';
import Notifications from './views/notifications/Notifications';
import PrivateRoute2 from './PrivateRoute2';
import LastViewed from './components/last_viewed/LastViewed';
import { NotificationProvider } from './context/NotificationContext';
import Notification from './components/notification/Notification';

function AppContent() {
  const location = useLocation();
  //const {currentUser, userData} = useContext(AuthContext)
  const windowWidth = window.innerWidth;
  const isMobile = (windowWidth <= theme.breackpoint.mobile_2.replace("px", ""))
  const noHeaderFooterRoutes = ['/criar-conta', '/entrar'];
  const switchHeaderColor = ['/'].includes(location.pathname);
  const showHeaderFooter = !noHeaderFooterRoutes.includes(location.pathname);
  
  return (
    <NotificationProvider>
      <AuthProvider>
            {showHeaderFooter && !isMobile && <Header switchColor={switchHeaderColor} />}
            <Notification />
            <Routes>
                <Route index element={<Home />} />
                <Route path='/nossos-planos' element={<Plans />} />
                <Route path='/criar-conta' element={<CreateAccountPage />} />
                <Route path='/buscador' element={<SearchPage />} />
                <Route path='/anunciar' element={
                    <PrivateRoute2>
                        <RegisterPropertyPage />
                    </PrivateRoute2>} />
                <Route path='/imovel/:id' element={<ViewProperty />} />
                <Route path='/blog' element={<BlogHome />} />
                <Route path='/blog/:slug' element={<Blog />} />
                <Route path='/pagamento/:id/:planId/:clientId' element={
                    <PrivateRoute2>
                        <Payment />
                    </PrivateRoute2>
                } />
                <Route path='/editar-anuncio/:propertyId' element={
                    <PrivateRoute2>
                        <EditPropertyPage />
                    </PrivateRoute2>
                } />
                <Route path='/entrar' element={<Loggin />} />
                <Route path="/meus-termos-de-uso" element={<TermsOfUse />} />
                <Route path="/meus-chats" element={
                    <PrivateRoute2>
                        <MyChats />
                    </PrivateRoute2>
                } />
                <Route path="/alterar-senha" element={
                        <MyChangePassword />
                } />
                <Route path="/vistos-por-ultimo" element={
                    <PrivateRoute2>
                        <LastViewed />
                    </PrivateRoute2>
                } />
                <Route path="/institucional" element={<MyInstitucional />} />
                <Route path="/meus-imoveis" element={
                    <PrivateRoute2>
                        <ContainerMyProperties />
                    </PrivateRoute2>
                } />
                <Route path="/meus-imoveis-favoritos" element={
                    <PrivateRoute2>
                        <MyFavorites />
                    </PrivateRoute2>
                } />
                <Route path="/chat/:chatid" element={
                    <PrivateRoute2>
                        <ChatContainer />
                    </PrivateRoute2>
                }/>
                <Route path='/meus-dados' element={
                    <PrivateRoute2>
                        <MyData />
                    </PrivateRoute2>
                } />
                <Route path='/notificacoes' element={
                    <PrivateRoute2>
                        <Notifications />
                    </PrivateRoute2>
                }/>
            </Routes>
            {isMobile && <HeaderMobile />}
            {showHeaderFooter && !isMobile && <Footer newsletter={false} />}
      </AuthProvider>
    </NotificationProvider>
  );
}


function App() {
    return (
        <div className="App">
            <LoadScript googleMapsApiKey='AIzaSyDVrDZ19GzGLSdUt4JEEQ1RXQK9N1IE24Y' libraries={["places"]}  >
                <BrowserRouter>
                    <AppContent />
                </BrowserRouter>
            </LoadScript>
        </div>
    );
}

export default App;
