import { useContext, useEffect, useRef, useState } from "react";
import NavBar from "../../components/nav-bar/NavBar";
import WrapperMyData from "./MyData.styles";
import { AuthContext } from './../../context/AuthContext';
import { updateUserInfo } from "../../hooks/firebase";
import { GoLock } from "react-icons/go";
import { FiMapPin } from "react-icons/fi";
import axios from "axios";
import { NotificationContext } from "../../context/NotificationContext";


const MyData = () => {
    const {currentUser, userData, reloadUserData} = useContext(AuthContext)
    const {addNotification} = useContext(NotificationContext)
    const [name, setName] = useState("")
    const [telephone, setTelephone] = useState("")
    const [document, setDocument] = useState("")
    const [cep, setCEP] = useState("")
    const [city, setCity] = useState("")
    const [street, setStreet] = useState("")
    const [state, setState] = useState("")
    const [neightboard, setNeightboard] = useState("")
    const [number, setNumber] = useState("")
    const [creci, setCreci] = useState(false)
    const [complement, setComplement] = useState("")
    const [documentLimit, setDocumentLimit] = useState(0)
    const CEPFeedbackRef = useRef(null)
    const feedbackIsValidDocument = useRef(null)


    useEffect(() => {
        let isMounted = true;
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        
        setName(userData?.name)
        setTelephone(userData?.telephone)
        setDocument(userData?.document)
        setCEP(userData?.myLocation.cep)
        setCity(userData?.myLocation.city)
        setState(userData?.myLocation.state)
        setStreet(userData?.myLocation.street)
        setNeightboard(userData?.myLocation.neightboard)
        setNumber(userData?.myLocation.number)
        setComplement(userData?.myLocation.complement)

        userData?.creci !== "" ? 
            setCreci(userData?.creci) :
            setCreci(false)

        userData?.type === "CPF"
            ? setDocumentLimit(14)
            : setDocumentLimit(18)

        return () => {
            isMounted = false;
        };
    },[userData])

    function handleSetDocument(event) {
        const inputValue = (event.target.value).slice(0, documentLimit)
        const formattedValue = formatCPFOrCNPJ(inputValue); 
        setDocument(formattedValue);
    
        const isValid = isValidDocument(formattedValue); 
    
        
        feedbackIsValidDocument.current.innerText = isValid
            ? "" 
            : "O Documento informado é inválido!";
    }

    function formatCPFOrCNPJ(value) {
        const cleanValue = value.replace(/\D/g, '');
    
        if (cleanValue.length <= 11) {
            if (cleanValue.length <= 2) return cleanValue;
            if (cleanValue.length <= 5) return `${cleanValue.slice(0, 3)}.${cleanValue.slice(3)}`;
            if (cleanValue.length <= 8) return `${cleanValue.slice(0, 3)}.${cleanValue.slice(3, 6)}.${cleanValue.slice(6)}`;
            return `${cleanValue.slice(0, 3)}.${cleanValue.slice(3, 6)}.${cleanValue.slice(6, 9)}-${cleanValue.slice(9, 11)}`;
        }
    
        if (cleanValue.length <= 14) {
            if (cleanValue.length <= 2) return cleanValue;
            if (cleanValue.length <= 5) return `${cleanValue.slice(0, 2)}.${cleanValue.slice(2)}`;
            if (cleanValue.length <= 8) return `${cleanValue.slice(0, 2)}.${cleanValue.slice(2, 5)}.${cleanValue.slice(5)}`;
            if (cleanValue.length <= 12) return `${cleanValue.slice(0, 2)}.${cleanValue.slice(2, 5)}.${cleanValue.slice(5, 8)}/${cleanValue.slice(8)}`;
            return `${cleanValue.slice(0, 2)}.${cleanValue.slice(2, 5)}.${cleanValue.slice(5, 8)}/${cleanValue.slice(8, 12)}-${cleanValue.slice(12, 14)}`;
        }
    
        return cleanValue;
    }

    function isValidDocument(inputDocument) {
        const doc = inputDocument || document;
        if (doc === "" || doc === null) return false;
        let tempDocument = doc.replace(/\D/g, '');
    
        function isValidCPF(cpf) {
            if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;
            let sum = 0;
            for (let i = 0; i < 9; i++) sum += parseInt(cpf[i]) * (10 - i);
            let firstVerifier = (sum * 10) % 11;
            if (firstVerifier === 10 || firstVerifier === 11) firstVerifier = 0;
            if (firstVerifier !== parseInt(cpf[9])) return false;
            sum = 0;
            for (let i = 0; i < 10; i++) sum += parseInt(cpf[i]) * (11 - i);
            let secondVerifier = (sum * 10) % 11;
            if (secondVerifier === 10 || secondVerifier === 11) secondVerifier = 0;
            return secondVerifier === parseInt(cpf[10]);
        }
    
        function isValidCNPJ(cnpj) {
            if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) return false;
            let sum = 0;
            const firstWeights = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
            for (let i = 0; i < 12; i++) sum += parseInt(cnpj[i]) * firstWeights[i];
            let firstVerifier = sum % 11 < 2 ? 0 : 11 - (sum % 11);
            if (firstVerifier !== parseInt(cnpj[12])) return false;
            sum = 0;
            const secondWeights = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
            for (let i = 0; i < 13; i++) sum += parseInt(cnpj[i]) * secondWeights[i];
            let secondVerifier = sum % 11 < 2 ? 0 : 11 - (sum % 11);
            return secondVerifier === parseInt(cnpj[13]);
        }
    
        if (tempDocument.length === 11) {
            return isValidCPF(tempDocument);
        } else if (tempDocument.length === 14) {
            return isValidCNPJ(tempDocument);
        } else {
            return false;
        }
    }

    function handleIsValidDocument() {
        let isValid = isValidDocument()
        if (!isValid) {
            feedbackIsValidDocument.current.innerText = "O Documento informado é invalido!"
        } else {
            feedbackIsValidDocument.current.innerText = ""
        }
    }

    function handleSetTelephone (event) {
        let tempTelephone = event.currentTarget.value
        tempTelephone = tempTelephone.slice(0, 15)
        let temp = formatPhoneNumber(tempTelephone)
        setTelephone(temp)
    }

    function formatPhoneNumber(value) {
        const cleanValue = value.replace(/\D/g, '');
        if (cleanValue.length === 0) return '';
    
        if (cleanValue.length <= 2) {
            return `(${cleanValue}`;
        } else if (cleanValue.length <= 6) {
            return `(${cleanValue.slice(0, 2)}) ${cleanValue.slice(2)}`;
        } else if (cleanValue.length <= 10) {
            return `(${cleanValue.slice(0, 2)}) ${cleanValue.slice(2, 6)}-${cleanValue.slice(6)}`;
        } else {
            return `(${cleanValue.slice(0, 2)}) ${cleanValue.slice(2, 7)}-${cleanValue.slice(7, 11)}`;
        }
    }

    function save () {
        let data = {
            uid: currentUser,
            name,
            telephone,
            document,
            creci,
            myLocation: {
                cep,
                city,
                street,
                state,
                neightboard,
                number,
                complement
            }
        }
        console.log(data)

        updateUserInfo(data).then(() => {
            reloadUserData()
            addNotification("Perfil atualizado com sucesso!", 'success')
        })
    }

    const handleCepBlur = (event) => {
        let cep = event.currentTarget.value
        let formattedCep = cep.replace(/\D/g, '');
        if (formattedCep.length > 5) {
            formattedCep = formattedCep.slice(0, 5) + '-' + formattedCep.slice(5, 9); // Adiciona o hífen
        }
    
        formattedCep = formattedCep.slice(0, 9);
        
        setCEP(formattedCep);
        if (formattedCep.length > 8) {
            axios.get(`https://viacep.com.br/ws/${formattedCep.replace("-", "")}/json/`)
            .then(result => {
                const data = result.data;
                if (data.erro) {
                    CEPFeedbackRef.current.innerText = "CEP incorreto ou inexistente";
                    setCity("")
                    setStreet("")
                    setNeightboard("")
                    setState("")
                    return;
                }
                setCity(data.localidade);
                setStreet(data.logradouro);
                setNeightboard(data.bairro);
                setState(data.uf);
                CEPFeedbackRef.current.innerText = ""
            }).catch(e => {

            })
        } else {
            CEPFeedbackRef.current.innerText = "";
        }
    };

    const handleSetName = (event) => {
        let sanitizedName = (event.currentTarget.value).slice(0, 60)
        setName(sanitizedName)
    }

    const handleSetNumber = event => {
        let sanitizedNumber = (event.currentTarget.value).slice(0, 8)
        setNumber(sanitizedNumber)
    }

    const handleSetComplement = event => {
        let sanitizedComplement = (event.currentTarget.value).slice(0, 30)
        setComplement(sanitizedComplement)
    }

    return <WrapperMyData>
        <NavBar />
        <div className="container-a">
            <div className="header">
                <GoLock /> Meus dados
            </div>
            <div className="line">
                <span className="title">Nome Completo</span>
                <input type="text" value={name} onChange={handleSetName} />
            </div>
            <div className="line">
                <span className="title">Email</span>
                <input type="text" value={userData?.email} disabled />
            </div>
            <div className="line">
                <span className="title">Documento</span>
                <input type="text" value={document} onChange={handleSetDocument} />
                <span className="feedback" ref={feedbackIsValidDocument}></span>
            </div>
            {creci && 
                <div className="line">
                    <span className="title">Creci</span>
                    <input type="text" value={creci} onChange={(e) => setCreci(e.currentTarget.value)} />
                </div>
            }
            <div className="line">
                <span className="title">Telefone/Whatsapp</span>
                <input type="text" value={telephone} onChange={handleSetTelephone} />
            </div>
            <div className="header address">
                <FiMapPin /> Meu endereço
            </div>
            <div className="line">
                <span className="title">Cep</span>
                <input type="text" value={cep} onChange={handleCepBlur} />
                <span className="feedback" ref={CEPFeedbackRef}></span>
            </div>
            <div className="line">
                <span className="title">Cidade</span>
                <input type="text" value={city} disabled />
            </div>
            <div className="line">
                <span className="title">Estado</span>
                <input type="text" value={state} disabled />
            </div>
            <div className="line">
                <span className="title">Rua</span>
                <input type="text" value={street} disabled />
            </div>
            <div className="line">
                <span className="title">Bairro</span>
                <input type="text" value={neightboard} disabled />
            </div>
            <div className="line">
                <span className="title">Número</span>
                <input type="text" value={number} onChange={handleSetNumber} />
            </div>
            <div className="line">
                <span className="title">Complemento</span>
                <input type="text" value={complement} onChange={handleSetComplement} />
            </div> 
            <div className="actions">
                <span className="button" onClick={save}>
                    Salvar
                </span>
            </div>
        </div>
    </WrapperMyData>
}

export default MyData