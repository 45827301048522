import { Autocomplete } from "@react-google-maps/api";
import { useState, useRef, useEffect } from "react";
import WrapperMainBanner from "./Styles-MainBanner";
import Family from "../../imgs/CASAL 02.png";
import { BsPinMap } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { FaSearch } from "react-icons/fa";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";

const MainBanner = () => {
  const isMobile = window.innerWidth <= theme.breackpoint.mobile_2.replace("px", "");
  const [city, setCity] = useState("");
  const [type, setType] = useState(false);
  const navigate = useNavigate();
  const text = isMobile ? 
  <>
    <span className="bold">o imóvel ideal para comprar em </span>
    <span className="bold">qualquer lugar do país!</span>
  </>
  :
  <>
    <span className="bold">o imóvel ideal para comprar em </span> <br />
    <span className="bold">qualquer lugar do país!</span>
  </>
  const [selectboxValue, setSelectBoxValue] = useState(isMobile ? "Imóvel" : "Tipo do Imóvel");
  const [showOptions, setShowOptions] = useState(false);
  const autocompleteRef = useRef(null);
  const selectBoxRef = useRef(null); // Referência para o select box
  const options = [
    {option: "Apartamento"},
    {option: "Área Comercial"},
    {option: "Casa"},
    {option: "Casa Comercial"},
    {option: "Casa Em Condomínio"},
    {option: "Chácara"},
    {option: "Galpão/Armazém"},
    {option: "Kitnet/Studio"},
    {option: "Prédio Comercial"},
    {option: "Rancho"},
    {option: "Sala Comercial"},
    {option: "Salão Comercial"},
    {option: "Sítio/Fazenda"},
    {option: "Terreno"},
    {option: "Terreno Comercial"},
    {option: "Terreno Em Condomínio"},
  ];

  useEffect(() => {
    // Função para detectar cliques fora do select box
    const handleClickOutside = (event) => {
      if (selectBoxRef.current && !selectBoxRef.current.contains(event.target)) {
        setShowOptions(false); // Fecha o menu de opções
      }
    };

    // Adiciona o event listener ao document
    document.addEventListener("mousedown", handleClickOutside);

    // Remove o event listener ao desmontar o componente
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOption = (value) => {
    setType(value);
    setSelectBoxValue(value);
    setShowOptions(false);
  };

  const handlePlaceChanged = () => {
    const autocomplete = autocompleteRef.current;
    
    if (autocomplete) {
      const place = autocomplete.getPlace();

      if (place) {
        const addressComponents = place.address_components;
        const cityComponent = addressComponents.find(component => component.types.includes("locality"));
        const countryComponent = addressComponents.find(component => component.types.includes("country"));

        if (cityComponent && countryComponent && countryComponent.short_name === "BR") {
          // Define apenas o nome da cidade no input
          setCity(cityComponent.long_name);
        } else {
          console.error("O local selecionado não é uma cidade no Brasil.");
        }
      } else {
        console.error("Nenhum lugar foi selecionado.");
      }
    } else {
      console.error("Autocomplete não está disponível.");
    }
  };

  const handleClick = () => {
    let data = {
      city,
      type
    };

    navigate("/buscador", { state: data });
  };

  const handleSetCity = event => {
    let value = (event.target.value).slice(0, 35)
    setCity(value)
  }

  return (
    <WrapperMainBanner>
      <div className="title">
      A maneira mais fácil de encontrar <br />
      {text}
      </div> 
      <div className="ilustration">
        <div className="family">
          <img src={Family} alt="Casal" />
        </div>
      </div>
      <div className="search_box">
        <div className="input_box">
          <BsPinMap /> 
          <Autocomplete
            onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
            onPlaceChanged={handlePlaceChanged}
            types={['(cities)']}
            componentRestrictions={{ country: "BR" }}
          >
            <input 
              type="text" 
              placeholder="Cidade" 
              value={city} 
              onChange={handleSetCity}
            />
          </Autocomplete>
        </div> 
        <div className="select-box" ref={selectBoxRef}> {/* Referência ao select box */}
          <div className="custom-selectbox">
            <span className="value" onClick={() => setShowOptions(!showOptions)}>{selectboxValue}</span>
            <div className={`options ${showOptions && "show"}`}>
              {options.map((option, index) => (
                <span
                  key={index + option.option}
                  className={`option${selectboxValue === option.option ? " selected" : ""}`}
                  onClick={() => handleOption(option.option)}
                >
                  {option.option}
                </span>
              ))}
            </div>
          </div>
          <IoIosArrowDown className={showOptions && "actived"} onClick={() => setShowOptions(!showOptions)} />
        </div>
        <span onClick={handleClick} className="button">
          Buscar 
          <FaSearch className="search-mobile-icon" />
        </span>
      </div>
    </WrapperMainBanner>
  );
};

export default MainBanner;
