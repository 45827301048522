import { useContext } from "react";
import { NotificationContext } from "../../context/NotificationContext";
import styled, { keyframes } from "styled-components";

// Define a animação para a entrada
const slideInFromTop = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Define a animação para a saída
const slideOutToTop = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  z-index: 1000;
  visibility: hidden; /* Começa invisível */
  display: flex;
  justify-content: center; /* Centraliza o conteúdo horizontalmente */
  align-items: center; /* Centraliza o conteúdo verticalmente */
  pointer-events: none; /* Evita que o clique passe pela notificação */
  
  /* Quando a notificação está ativa (visível), ela entra com a animação */
  &.show {
    visibility: visible;
    pointer-events: auto; /* Ativa eventos de clique quando visível */
    .body {
      animation: ${slideInFromTop} 0.5s ease-out forwards;
    }
  }

  /* Quando a notificação for para sair, usamos um delay para ela começar a sair */
  &.show {
    animation: ${slideInFromTop} 0.5s ease-out forwards,
               ${slideOutToTop} 0.5s ease-out 4.5s forwards; /* A animação de saída começa depois de 4.5s */
  }
`;

const Body = styled.div`
  background-color: ${({ type }) =>
    type === "success" ? "#4caf50" :
    type === "error" ? "#f44336" :
    "#2196f3"}; /* Cor para info, que é o default */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  max-width: 50%; /* Largura fixa da notificação */
  word-wrap: break-word; /* Quebra as palavras longas */
  text-align: center; /* Centraliza o texto */
`;

const Notification = () => {
  const { notification } = useContext(NotificationContext);

  if (!notification) return null;

  return (
    <Wrapper className="show">
      <Body type={notification.type}>
        <span className="message">{notification.message}</span>
      </Body>
    </Wrapper>
  );
};

export default Notification;
