import styled from "styled-components";
import theme from "../../theme";

const WrapperMyPlan = styled.div`
    margin: 30px 12%;
    display: flex;
    justify-content: center;
    position: relative;

    .container{
        display: flex;
        flex-direction: column;
        gap: 7px;
        width: 55%;
        margin-left: 50px;
        margin-top: 50px;

        .plan {
            font-size: 3rem;
            font-weight: 600;
            color: #01bbb6;
            font-family:  ${theme.fontFamily.extraBold};
        }

        .line {
            display: flex;
            align-items: center;
            gap: 15px;
            margin-bottom: 10px;

            img {
                width: 25px;
            }

            &:nth-child(6) {
                margin-top: 30px;
                justify-content: flex-start;
                align-items: flex-start;
                font-weight: 600;
                color: rgb(60 ,60 ,60 );

                .bold {
                    margin-left: -7px;
                    font-weight: 600;
                    color: #01bbb6;
                }
            }
        }

        .button {
            margin-top: 30px;
            padding: 6px 40px;
            border-radius: 100px;
            font-size: .875rem;
            background-color: #01bbb6;
            align-self: flex-start;
            color: white;
            font-weight: 600;
        }
    }
    
    @media (max-width: ${theme.breackpoint.mobile_2}) {
        margin-bottom: 300px;

        .container {
            width: 100%;
            margin-left: 0;
            position: relative;
        }
    }
    
    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        margin: 30px 2%;

        .container {
            width: 50%;
        }
    }
    
    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        margin: 30px 8%;

        .container {
            width: 50%;
        }
    }
    
    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        margin: 30px 8%;

        .container {
            width: 50%;
        }
    }
    
    @media (max-width: ${theme.breackpoint.bigScreens}) and (min-width: ${theme.breackpoint.largeScreens}) {
        
    }
`

export default WrapperMyPlan