import styled from "styled-components";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { IoChevronBackOutline } from "react-icons/io5";
import { IoChevronForwardOutline } from "react-icons/io5";

const WrapperSlideShowTypeC = styled.div`
    cursor: grab;
    overflow: hidden;
    width: 100%;
    user-select: none;

    .inner {
        display: flex;
        gap: 15px;

        .image {
            min-width: 400px;
            position: relative;

            img {
                max-width: 400px;
                min-height: 300px;
                pointer-events: none;
            }
        }
    }
`;

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.9);
    z-index: 1000;

    img {
        max-width: 90%;
        max-height: 90%;
        border-radius: 10px;
    }

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 2rem;
        color: white;
        cursor: pointer;
    }

    .nav {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: rgba(255, 255, 255, 0.7);
        border: none;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 1.5rem;
        color: black;

        &.prev {
            left: 10px;
        }

        &.next {
            right: 10px;
        }
    }
`;

const SlideShowTypeC = ({ images }) => {
    const CarouselRef = useRef(null);
    const [width, setWidth] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(null);

    useEffect(() => {
        const updateWidth = () => {
            if (CarouselRef.current) {
                const totalWidth =
                    CarouselRef.current.scrollWidth - CarouselRef.current.offsetWidth;
                setWidth(totalWidth);
            }
        };

        updateWidth();
        window.addEventListener("resize", updateWidth);

        return () => {
            window.removeEventListener("resize", updateWidth);
        };
    }, []);

    const openModal = (index) => setCurrentIndex(index);
    const closeModal = () => setCurrentIndex(null);

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    return (
        <>
            <WrapperSlideShowTypeC>
                <motion.div
                    className="images"
                    whileTap={{ cursor: "grabbing" }}
                    ref={CarouselRef}
                >
                    <motion.div
                        className="inner"
                        drag="x"
                        dragConstraints={{ right: 0, left: -width }}
                    >
                        {images.map((image, index) => (
                            <motion.div
                                className="image"
                                key={index}
                                onClick={() => openModal(index)}
                            >
                                <img src={image} alt={`Slide ${index}`} />
                            </motion.div>
                        ))}
                    </motion.div>
                </motion.div>
            </WrapperSlideShowTypeC>

            {currentIndex !== null && (
                <Modal>
                    <span className="close" onClick={closeModal}>
                        &times;
                    </span>
                    <img src={images[currentIndex]} alt="Modal" />

                    {/* Botões de Navegação */}
                    <button className="nav prev" onClick={handlePrev}>
                        <IoChevronBackOutline /> {/* Seta para esquerda */}
                    </button>
                    <button className="nav next" onClick={handleNext}>
                        <IoChevronForwardOutline /> {/* Seta para direita */}
                    </button>
                </Modal>
            )}
        </>
    );
};

export default SlideShowTypeC;
