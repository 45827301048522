import styled from "styled-components";
import theme from "../../theme";

const WrapperHeaderMobile = styled.div`
    

    .top {
        width: 90%;
        padding: 0 5%;
        padding-top: 5%;
        background-color: white;
        position: fixed;
        top: 0;
        height: 100vh;
        z-index: 10;
        display: none;
        flex-direction: column;
        gap: 15px;

        &.show{
            display: flex;
        }

        .user {
            display: flex;
            gap: 10px;

            .user-img {
                width: 60px;
                height: 60px;
                border-radius: 100px;
                background-color: gray;
                position: relative;

                svg {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
            }
            
            img {
                width: 60px;
                height: 60px;
                border-radius: 100px;
            }

            span {
                font-family: ${theme.fontFamily.medium};

            }
        }

        a {
            display: flex;
            color: black;
            text-decoration: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1rem;

            .info {
                display: flex;
                align-items: center;
                gap: 20px;
            }
        }
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        width: calc(100%);
        padding: 10px 0px;
        background-color: ${theme.fontColor.green};
        position: fixed;
        bottom: 0;
        z-index: 10;

        .item {
            width: 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: white;
            text-decoration: none;

            svg {
                font-size: 1.4rem;
            }

            span {
                font-size: .75rem;
            }
        }
    }
    
`

export default WrapperHeaderMobile