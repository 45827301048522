import styled from "styled-components";
import theme from "../../theme";

const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 18%;
  padding-right: 18%;
  background-color: ${props => props.switchColor ? "white" : theme.backgroundColor.green};
 
  .logo {
    display: flex;
    align-items: center;
    img {
      width: 150px;
    }
  }

  .links {
    display: flex;
    align-items: center;
    background-color: ${props => props.switchColor ? "white" : theme.fontColor.green} !important;

    a {
      text-decoration: none;
      color: ${props => props.switchColor ? "#111111" : "white"};
      font-size: .955rem;
      font-weight: 600;

      &:nth-child(1) {
        margin-right: 45px;
      }
      &:nth-child(2) {
        margin-right: 45px;
      }
      &:nth-child(3) {
        margin-right: 25px;
      }
      &:nth-child(4) {
        margin-right: 23px;
        background-color: ${props => props.switchColor ? theme.fontColor.green : "white"};
        padding: 10px 25px;
        border-radius: 20px;
        color: ${props => props.switchColor ? "white" : theme.fontColor.green};
      }
      &:nth-child(5) {
        margin-right: 15px;
        background-color: ${props => props.switchColor ? theme.fontColor.green : "white"};
        padding: 10px 25px;
        border-radius: 20px;
        color: ${props => props.switchColor ? "white" : theme.fontColor.green };
      }
      &:nth-child(8) {
        padding: 2px 10px;
        border-radius: 10px;
        border: 1px solid ${props => props.switchColor ? "#111111" : "white"};
        color: ${props => props.switchColor ? "#111111" : "white"};
      }
      
    }
    .notifications {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;

        .quantity {
          position: absolute;
          right: 0;
          top: 0;
          transform: translateY(-10px) translateX(7px);
          font-size: .85rem;
          color: white;
          padding: 0 5px;
          border-radius: 100px;
          background-color: ${props => props.switchColor ? theme.fontColor.green : "white"};
          color: ${props => props.switchColor ? "white" : theme.fontColor.green};
        }

        svg {
          font-size: 1.4rem;
          color: ${props => props.switchColor ? "#111111" : "white"};

        }

      }
  }

  .mobile-burger-button{
    display: none;
  }

  @media (max-width: ${theme.breackpoint.mobile_2}) {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10%;
    padding-right: 10%;
    z-index: 1;
    position: relative;

    .logo {
      width: 40%;
      display: flex;
      align-items: center;

      img {
        width: 100%;
        margin-bottom: 6px;
      }
    }

    .filler-mobile {
        width: 100%;
        margin-left: -22%;
        height: 200vh;
        background-color: rgba(0,0,0,.4);
        position: fixed;

    }

    .links {
        display: none;
        flex-direction: column;
        height: 80vh;
        padding: 0 10%;
        padding-top: 20vh;
        position: fixed;
        top: 0;
        right: 0;
        background-color: #f5f4f4;
        align-items: center;
        gap: 20px;
        z-index: 3;

        a {
            margin: 0 !important;
            text-align: center;
        }


        &.active {
            display: flex;
        }
    } 

    .mobile-burger-button {
      user-select: none;
      position: ${props => props.isActive ? "fixed" : "normal"};
      right: ${props => props.isActive && "10%"};
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 9;

      svg {
        color: ${props => props.switchColor ? "black" : "white"};
        font-size: 60px;
      }
    }
  }


  @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 7%;
    padding-right: 7%;
    
    .logo {
      img {
        width: 120px;
        margin-top: 5px;
      }
    }
    
    .links {
      a {
        font-size: .85rem;

        &:nth-child(1) {
          margin-right: 25px;
        }
        &:nth-child(2) {
          margin-right: 25px;
        }
        &:nth-child(3) {
          margin-right: 25px;
        }

        &:nth-child(6) {
          font-size: 15px;
        }
      }
    }
  }
  
  @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10%;
    padding-right: 10%;

    .logo {
      img {
        width: 150px;
      }
    }
    
    .links {
      a {
        font-size: .9rem !important;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          margin-right: 25px;
        }
      }
    }
  }

  @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 13%;
    padding-right: 13%;
    
    .links {
      a {
        font-size: 1.1rem !important;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          margin-right: 32px;
        }
      }
    }
  }

  @media (max-width: ${theme.breackpoint.bigScreens}) and (min-width: ${theme.breackpoint.largeScreens}) {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15%;
    padding-right: 15%;
    
    .links {
      a {
        font-size: 1rem !important;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          margin-right: 37px;
        }
      }
    }
  }
`;

export default WrapperHeader