import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../../hooks/firebase";
import WrapperMyData from "../../views/my-data/MyData.styles";
import NavBar from "../nav-bar/NavBar";
import { FaRegPaperPlane, FaUserAlt } from "react-icons/fa";
import WrapperChatContainer from "./ChatContainer.styles";

const ChatContainer = (props) => {
    const { chatid } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [dataParticipant, setDataParticipant] = useState(null)
    const [toggle, setToggle] = useState(false)
    const [message, setMessage] = useState('');
    const { currentUser, userData } = useContext(AuthContext);

    const handleBackToChatSelection = () => {
        navigate("/meus-chats");
    };

    useEffect(() => {
        const chatRef = doc(db, "chats", chatid);

        const unsubscribe = onSnapshot(chatRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const tempData = docSnapshot.data()
                if (tempData.ownerId === currentUser) {
                    const userRef = doc(db, "users", tempData.participantId);
                    getDoc(userRef).then(result => {
                        setDataParticipant(result.data())
                        setToggle(true)
                    });
                } else {
                    const userRef = doc(db, "users", tempData.ownerId);
                    getDoc(userRef).then(result => {
                        setDataParticipant(result.data())
                        setToggle(false)
                    });
                }

                setData(docSnapshot.data());
            } else {
                console.log("O documento do chat não existe!");
            }
        }, (error) => {
            console.error("Erro ao buscar o chat em tempo real: ", error);
        });

        // Cancela o listener quando o componente é desmontado
        return () => unsubscribe();
    }, [chatid]);

    const inputSendMessage = (event) => {
        if (event.key === "Enter") {
            sendMessage()
        }   
    }

    const sendMessage = async () => {
        if (message.length > 0) {
            try {
                const chatRef = doc(db, "chats", chatid);
                const { body } = data;
                await updateDoc(chatRef, {
                    body: [
                        ...body,
                        {
                            username: userData.name,
                            userId: currentUser,
                            sendedAt: new Date(),
                            message: message,
                        },
                    ]
                });
                setMessage("");
            } catch (error) {
                if (error instanceof Error) {
                    console.error(error.message);
                } else {
                    console.error("Erro desconhecido ao enviar mensagem.");
                }
            }
        }
    };

    function obterHoraMinuto(createdAt) {
        if (!createdAt) return;
    
        // Converter o timestamp para milissegundos
        const totalMilliseconds = (createdAt.seconds * 1000) + (createdAt.nanoseconds / 1_000_000);
        const date = new Date(totalMilliseconds);
    
        // Obter a data atual e calcular a diferença em milissegundos
        const now = new Date();
        const diff = now - date;
    
        // 24 horas em milissegundos
        const oneDayInMs = 24 * 60 * 60 * 1000;
    
        if (diff < oneDayInMs) {
            // Dentro de 24 horas: retornar somente o horário
            const horas = date.getHours();
            const minutos = date.getMinutes();
            return `${horas}:${minutos.toString().padStart(2, '0')}`;
        } else {
            // Mais de 24 horas: retornar no formato hh:mm - dd/mm/yy
            const horas = date.getHours();
            const minutos = date.getMinutes();
            const dia = date.getDate().toString().padStart(2, '0');
            const mes = (date.getMonth() + 1).toString().padStart(2, '0'); // Meses começam do 0
            const ano = date.getFullYear().toString().slice(-2); // Pegar os dois últimos dígitos do ano
            return `${horas}:${minutos.toString().padStart(2, '0')} - ${dia}/${mes}/${ano}`;
        }
    }

    function handleSetMessage(event) {
        let value = (event.currentTarget.value).slice(0, 180)
        setMessage(value)
    }

    return (
        <WrapperChatContainer> 
            <NavBar />
            <div className="container-chat">
                {data ? 
                    <div className="main"> 
                        <span className="back" onClick={handleBackToChatSelection}>Voltar</span>
                        <div className="header-chat">
                            {dataParticipant?.photoURL !== "" ?
                                <img src={dataParticipant?.photoURL} alt="" />
                                :
                                <div className="user-icon">
                                    <FaUserAlt />
                                </div>
                            }
                            {/* <img src={dataParticipant?.photoURL} title={dataParticipant?.name} /> */}
                            <span className="username">{dataParticipant?.name}</span>
                        </div>
                        <div className="body-chat">
                            <div className="space"></div>
                            {data.body.map((message, index) => (
                                <div key={index} className={`message${currentUser === message.userId ? " sended" : " received"}`}>
                                    <span className="username">{message.username}</span>
                                    <span className="content">{message.message}</span>
                                    <span className="moment">{obterHoraMinuto(message.sendedAt)}</span>
                                </div>
                            ))}
                        </div>
                        <div className="inputbox">
                            <div className="input-container">
                                <input placeholder="Digite..." type="text" value={message} onKeyDown={inputSendMessage} onChange={handleSetMessage} />
                                <FaRegPaperPlane onClick={sendMessage} />
                            </div>
                        </div>
                    </div>
                    :
                    <span>Carregando chat...</span>
                }
            </div>
        </WrapperChatContainer>
    );
};

export default ChatContainer
