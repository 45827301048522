import styled from "styled-components";
import theme from "../../theme";

const WrapperSuggestions = styled.div`
    margin: 0 18%;
    margin-top: 85px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title{
        font-size: 1.5rem !important;
        font-family: ${theme.fontFamily.regular};
        letter-spacing: 1px; 
        text-align: center;
        margin-bottom: 40px;
    }

    .container {
        display: flex;
        justify-content: space-between;
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        margin: 0 5%;
        margin-top: 30px;
        
        .title {
            font-size: 30px;
            margin-bottom: 30px;
        }

        .container {
            flex-direction: column;
            align-items: center;
            gap: 40px;
        }
    }

    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}){
        margin: 0 13%;

        .title {
            font-size: 32px;
            margin-bottom: 30px;
        }
        
        .container {
            flex-wrap: wrap;
        }
    }

    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}){
        margin: 0 12%;
        margin-top: 50px;

        .title {
            font-size: 37px;
            margin: 20px;
        }
    }

    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}){
        margin: 0 13%;


        .title {
            font-size: 42px;
            margin: 40px;
        }
    }
`

export default WrapperSuggestions