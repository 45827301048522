import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { clearViewHistory, db, getPropertiesViewedByUser } from "../../hooks/firebase";
import MyProperty from "../my-property/MyProperty";
import WrapperMyData from "../../views/my-data/MyData.styles";
import NavBar from "../nav-bar/NavBar";
import { NotificationContext } from "../../context/NotificationContext";

const LastViewed = () => {
    const [properties, setProperties] = useState(null)
    const [filteredProperties, setFilteredProperties] = useState([]);
    const [searchContext, setSearchContext] = useState("");
    const {currentUser} = useContext(AuthContext)
    const {addNotification} = useContext(NotificationContext)

    const handleKeyDown = (event) => {
        const searchValue = event.target.value.toLowerCase();
        const searchTerms = searchValue.split(' ').filter(term => term.trim() !== '');
        setSearchContext(searchValue);

        const filtered = properties.filter(property => {
            return searchTerms.every(term => 
                property.street.toLowerCase().includes(term) ||
                property.number.toLowerCase().includes(term) ||
                property.neightboard.toLowerCase().includes(term) ||
                property.city.toLowerCase().includes(term) ||
                property.state.toLowerCase().includes(term)
            );
        });

        setFilteredProperties(filtered);
    };

    const handleClearHistory = async () => {
        const result = clearViewHistory(currentUser)
        if (result) {
            setProperties([])
            setFilteredProperties([])
            addNotification("Histório limpo", "info")
        }
    }

    useEffect(() => {
        getPropertiesViewedByUser(currentUser).then(result => {
            setProperties(result)
            setFilteredProperties(result)
        })
    }, [])

    return <WrapperMyData nothing={properties?.length === 0}>
        <NavBar />
        <div className="container-favorites">
            {properties?.length >= 1 && <>
                <div className="search-box">
                    <span className="title">Buscar</span>
                    <span className="clear-history" onClick={handleClearHistory}>Limpar Histório</span>
                    <input type="text" onChange={handleKeyDown} placeholder="Ex: Condomínio Gaivota 2" />
                </div>
                <div className="properties">
                    {properties !== null && 
                        filteredProperties.map((property, index) => {
                            return <MyProperty
                                key={index+"property-element"}
                                data={{...property, currentUser: currentUser, setProperties, setFilteredProperties}}
                                hidden={true}
                                viewed={true}
                            />
                        })
                    }
                </div>
            </>}
            {properties?.length === 0 &&
                <div>
                    <span className="nothing-favorites">Nenhum imóvel no histórico, continue explorando o nosso site em busca do seu futuro lar dos sonhos :)</span>
                </div>
            }
        </div>
    </WrapperMyData>
}

export default LastViewed