import styled from "styled-components";
import HouseAdvertisementSession from "../../components/house_advertisement_session/HouseAdvertisementSession";
import Infos from "../../components/infos/Infos";
import MainBanner from "../../components/main_banner/MainBanner";
import MostSearched from "../../components/most_searched/MostSearched";
import Suggestions from "../../components/suggestions/Suggestions";
import WrapperHome from "./Styles-Home";
import SlideShowBanners from "../../components/slide_show_type_d/SlideShowTypeD";
import Banner2 from "../../imgs/BANNER 02.jpg"
import Banner1 from "../../imgs/BANNER 01.jpg"

const Teste = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px 18%;
    width: calc(100% - 36%);

    .main {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .item {
            width: 49%;
            height: 220px;
            background-color: black;
        }
    }
`
const Home = () => {

    return <WrapperHome>
        <MainBanner />
        <HouseAdvertisementSession title="Anúncios Turbinados:" />
        <MostSearched />
        <Infos />
        <HouseAdvertisementSession title="Sugestões para você" /> 
        <Suggestions />
        <SlideShowBanners items={[{banner: Banner1, to: "/anunciar"}, {banner: Banner2, to: "/buscador"}]} visibleItems={1} />
    </WrapperHome>
}

export default Home