import WrapperLoggin from "./Loggin.styles";
import IMG from "../../imgs/happy-african-american-young-family-bought-new-house.jpg"
import Logo from "../../imgs/ALUGUE IMÓVEIS.webp"
import { IoLogoFacebook } from "react-icons/io5";
import { FaGoogle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import loginUser from "../../hooks/LoginWithEmailAndPassword";
import { useContext, useRef, useState } from "react";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";
import firebase from "firebase/compat/app";
import { auth, sendPasswordReset } from "../../hooks/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { NotificationContext } from "../../context/NotificationContext";

const Loggin = () => {
    const inputPasswordRef = useRef(null)
    const feedbackInputEmail = useRef(null)
    const feedbackIsValidUser = useRef(null)
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [message, setMessage] = useState("")
    const [recoverPassword, setRecoverPassword] = useState(false)
    const {addNotification} = useContext(NotificationContext)

    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleIsValidEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(email);

        if (!isValid) {
            feedbackInputEmail.current.innerText = "Informe um email válido!"
        }
    }
 
    const handle = (href) => {
        navigate(href)
    }

    const handleEmail = (e) => {
        feedbackInputEmail.current.innerText = ""
        setEmail(e.currentTarget.value)
    }

    const handlePassword = (e) => {
        feedbackIsValidUser.current.innerText = ""
        setPassword(e.currentTarget.value)
    }

    const handleRecoverPassword = () => {
        sendPasswordReset(email).then((result) => {
            if (result.success) {
                setMessage("E-mail para redefinição de\nsenha enviado com sucesso!")
            } else {
                setMessage("Email inexistente")
            }
        })
    }

    const handleLogin = async () => {
        try {
            let result = await loginUser(email, password);
            if (result) {
                handle("/");
                addNotification("Bem vindo de volta!", "info")
            } else {
                feedbackIsValidUser.current.innerText = "Usuário ou senha incorretos!"
            }
        } catch (error) {
            console.error("Erro no login:", error);
        }
    };

    const handlePressEnterToLogin = (event) => {
        if (email !== "" && password !== "") {
            if (event.key === "Enter") {
                handleLogin()
            }
        }
    }
 
    const handleNextToPassword = (event) => {
        if (event.key === "Enter") {
            inputPasswordRef.current.focus()
        }
    }
    

    return <WrapperLoggin>
        <div className="ilustration">
            <img src={IMG} alt="" />
        </div> 
        <div className="form">
            <img className="logo" src={Logo} alt="" />
            <span className="title">Login</span>
            <div className="form-item">
                <span>Email:</span>
                <input type="text" onBlur={handleIsValidEmail} onChange={handleEmail} onKeyDown={handleNextToPassword} />
                <span className="feedback" ref={feedbackInputEmail}></span>
            </div>
            {!recoverPassword && <div className="form-item">
                <span>Senha:</span>
                <div className="input-container">
                    <input type={showPassword ? "text" : "password"} ref={inputPasswordRef} onKeyDown={handlePressEnterToLogin} onChange={handlePassword} />
                    {showPassword ? 
                        <FaRegEyeSlash onClick={handleShowPassword} />
                    :
                        <FaRegEye onClick={handleShowPassword} />
                    }
                </div>
            </div>}
            {recoverPassword ? 
                <>
                    
                    <span className="button" onClick={handleRecoverPassword}>
                        Recuperar Senha
                    </span>   
                    <span className="message">
                        {message.split("\n").map((frag, index) => {
                            if (index === 1) {
                                return <>
                                <strong>{frag}</strong>
                                <br />
                                </>
                            } else {
                                return <>
                                    {frag}
                                    <br />
                                </>
                            }
                        })}
                    </span>
                </> 
                :
                <>
                    <span className="feedback" ref={feedbackIsValidUser}></span>
                    <span className="button" onClick={handleLogin}>
                        Entrar
                    </span>
                </>
            }
            {!recoverPassword && 
                <span className="reset-password" onClick={() => setRecoverPassword(!recoverPassword)}>
                    Esqueci minha senha
                </span>
            }
            <span className="have-account">
                Não possui uma conta? <strong onClick={() => handle("/criar-conta")}>Cadastre-se aqui</strong>
            </span>
        </div>
    </WrapperLoggin>
}

export default Loggin