import styled from "styled-components";

const WrapperChatCard = styled.div`
    width: 87%;
    padding: 5px 5%;
    display: flex;
    border: 1px solid #c3c3c3;
    border-radius: 15px;
    position: relative;
    cursor: pointer; 

    img {
        width: 70px;
        height: 70px;
        border-radius: 100px;
        background-color: #c3c3c3;
    }

    .user-icon {
        width: 70px;
        height: 70px;
        border-radius: 100px;
        background-color: #c3c3c3;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        color: gray;
    }

    .body {
        display: flex;
        flex-direction: column;
        width: 65%;

        span {
            margin-left: 10px;
        }

        .username {
            font-weight: 600;
            font-size: 1rem;
        }

        .lastMessage{
            font-size: .92rem;
        }
    }

    .moment {
        width: calc(25% - 5px);
        position: absolute;
        font-size: .92rem;
        right: 0;
        margin-top: 2px;
        white-space: nowrap;
        display: flex;
        justify-content: end;
        margin-right: 5px;
    }
`

export default WrapperChatCard